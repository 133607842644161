import { CommonModule } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Passenger } from '../../interfaces/reservation';
import { PassengerService } from '../../services/passenger.service';
import { LocationAutocompleteComponent } from '../autocomplete/location-autocomplete/location-autocomplete.component';
export interface DiagReceived { object: boolean, value: Passenger };
@Component({
  selector: 'app-passenger-form',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatDatepickerModule,
    LocationAutocompleteComponent,
    NgxMaterialTimepickerModule,
    MatDialogModule
  ],
  templateUrl: './passenger-form.component.html',
  styleUrl: './passenger-form.component.css'
})
export class PassengerFormComponent implements OnInit {
  @Input() field: string = "";
  public private: boolean = false;
  public order: number = 0;
  public passenger: Passenger[] = [];
  public passengerForm: FormGroup = this.fb.group({
    uuid: [""],
    name: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(255)]],
    pax: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    description: [""],
    flight: [""],
    flight_date: [""],
    childs: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    babies: [0, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    location_uuid: [""],
    location_private: [""],
    reservation_uuid: [this.field, [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    time: [""]
  });

  constructor(private fb: FormBuilder, private passengerService: PassengerService, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private _snackBar: MatSnackBar) {
  }

  ngOnInit(){
    this.private = this.data.object;
    if(!!this.data.value.uuid){
      this.addPassenger(this.data.value);
    }else{
      this.passengerForm.patchValue({
        reservation_uuid: this.data.value.reservation_uuid
      });
    }
  }

  addPassenger(passenger: Passenger) {
    this.passengerForm.patchValue({
      uuid: passenger.uuid,
      name: passenger.name,
      description: passenger.description,
      pax: passenger.pax,
      flight: passenger.flight,
      flight_date: new Date(passenger.flight_date),
      childs: passenger.childs,
      babies: passenger.babies,
      location_uuid: passenger.location_uuid,
      location_private: passenger.location_private,
      reservation_uuid: passenger.reservation_uuid,
      time: new Date(passenger.flight_date).toLocaleTimeString("es-es",{hour:'numeric', minute:'2-digit'}),
    });
  }

  assignLocation(uuid: string){
    this.passengerForm.patchValue({
      location_uuid: uuid
    });
  }

  sendPassenger(){
    if(this.valid()){
      let newDate : Date = new Date(this.passengerForm.value.flight_date);
      newDate.setHours(parseInt(this.passengerForm.value.time.split(":")[0]), parseInt(this.passengerForm.value.time.split(":")[1]));
      this.passengerForm.value.flight_date = newDate;
      const passenger: Passenger = this.passengerForm.value;
      this.dialogRef.close(passenger);
    }else{
      this.openSnackBar("Rellena los campos necesarios");
    }
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.passengerForm.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  valid(){
    if(!this.passengerForm.value.name || (!this.passengerForm.value.location_uuid && !this.passengerForm.value.location_private) || (!this.passengerForm.value.pax && this.passengerForm.value.pax != 0) || (!this.passengerForm.value.childs && this.passengerForm.value.childs != 0) || (!this.passengerForm.value.babies && this.passengerForm.value.babies != 0)){
      return false;
    }
    return true;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }

}
