import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Staff } from '../../../interfaces/staff';
import { StaffService } from '../../../services/staff.service';
@Component({
  selector: 'app-staff-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './staff-autocomplete.component.html',
  styleUrl: './staff-autocomplete.component.css'
})
export class StaffAutocompleteComponent implements OnInit, OnDestroy {
  @Input() staff_uuid: string = "";
  @Output() selected_staff = new EventEmitter<string>();
  staffs: Staff[] = [];
  private staffsChangedSubscription: Subscription = {} as Subscription;
  staffFilteredOptions: Observable<Staff[]> = new Observable<Staff[]>();
  public staffForm: FormGroup = this.fb.group({
    staff_uuid: [""],
  })

  constructor(private staffService: StaffService, private fb: FormBuilder) { }

  ngOnInit() {
    this.staffFilteredOptions = this.staffForm.controls['staff_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.staffs;
      }),
    );
    this.getStaffs(true);
    this.staffsChangedSubscription = this.staffService.staffsChanged.subscribe({next: (net) => {
      if(net){
        this.getStaffs();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.staffsChangedSubscription) {
      this.staffsChangedSubscription.unsubscribe();
    }
  }

  getStaffs(search: boolean = false) {
    this.staffService.getAllStaffs().subscribe(data => {
      this.staffs = data;
      if(search){
        this.staffForm.patchValue({
          staff_uuid: this.staffs.filter(a => a.uuid == this.staff_uuid)[0]
        });
      }
    })
  }

  display(staff: Staff): string {
    return staff && staff.name ? staff.name : '';
  }

  private _filter(name: string): Staff[] {
    const filterValue = name.toLowerCase();
    return this.staffs.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_staff.emit(uuid);
  }

  clear(){
    this.staffForm.patchValue({
      staff_uuid: ""
    });
    this.selected_staff.emit();
  }
}
