import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Agency } from '../interfaces/agency';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';
@Injectable({
  providedIn: 'root',

})

export class AgencyService extends BaseService {

  agencies: Agency[] = [];
  private empty = new Subject<boolean>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "agency") {
        this.agencies = [];
        this.empty.next(true);
      }
    }});
  }

  getAllAgencies():Observable<Agency[]>{
    const obs:Subject<Agency[]> = new Subject<Agency[]>();
    if (this.agencies.length > 0) {
      setTimeout(() => {
        obs.next(...[this.agencies]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getAgencies()
      pet.subscribe((asd) => {
        this.agencies = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get agenciesChanged() {
    return this.empty.asObservable();
  }

  getAgencies():Observable<ApiListResponse<Agency>>{
    return this.http.get<ApiListResponse<Agency>>(this.apiUrl + "agency")
  }

  getAgencyById(id: string):Observable<ApiResponse<Agency>>{
    return this.http.get<ApiResponse<Agency>>(this.apiUrl + "agency/" + id)
  }

  addAgency(agency: Agency):Observable<ApiResponse<Agency>>{
    return this.http.post<ApiResponse<Agency>>(this.apiUrl + "agency", agency)
  }

  updateAgency(agency: Agency):Observable<ApiResponse<Agency>>{
    return this.http.put<ApiResponse<Agency>>(this.apiUrl + "agency/" + agency.uuid, agency)
  }

  deleteAgencyById(id: string):Observable<ApiResponse<Agency>>{
    return this.http.delete<ApiResponse<Agency>>(this.apiUrl + "agency/" + id)
  }
}
