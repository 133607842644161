import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { UpdateModalComponent } from '../components/modal/update-modal/update-modal.component';

@Injectable({
  providedIn: 'root'
})
export class UpdaterService {

  constructor(private readonly updates: SwUpdate, private _modal: MatDialog) {
    if (this.updates.isEnabled) {
      setInterval(() => {
      this.checkUpdates();
    }, 300000)}
  }

  public checkUpdates() {
    if (this.updates.isEnabled) {
      this.updates.checkForUpdate().then(updateAvaiable => {
        if (updateAvaiable) {
          this._modal.open(UpdateModalComponent, {
            closeOnNavigation: false,
            disableClose: true,
            width: '30%'
          });
        }
      });
    }else {
      console.warn("SW Not working")
    }

  }
}
