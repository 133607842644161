import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import {
  MatSnackBar
} from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { Staff } from '../../interfaces/staff';
import { StaffService } from '../../services/staff.service';
import { TitleService } from '../../services/title.service';
import { TouroperatorService } from '../../services/touroperator.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { NameModalComponent } from '../modal/name-modal/name-modal.component';
import { ShowPasswordComponent } from '../modal/show-password/show-password.component';
@Component({
  selector: 'app-staff',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatListModule
  ],
  templateUrl: './staff.component.html',
  styleUrl: './staff.component.css'
})
export class StaffComponent implements OnInit {
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;

  private staffsChangedSubscription: Subscription = {} as Subscription;
  public staffs: Array<Staff> = new Array<Staff>();
  constructor(private staffService: StaffService, private touroperatorService: TouroperatorService, private _snackBar: MatSnackBar, public dialog: MatDialog, private _title: TitleService) {
    _title.setTitle("Personal") }


  ngOnInit() {
    this.getAllStaffs();
    this.staffsChangedSubscription = this.staffService.staffsChanged.subscribe({next: (net) => {
      if(net){
        this.getAllStaffs();
      }
    }});
  }

  ngOnDestroy(): void {

    if (this.staffsChangedSubscription) {
      this.staffsChangedSubscription.unsubscribe();
    }
  }

  getAllStaffs() {
    this.staffService.getAllStaffs().subscribe(data => {
      this.staffs = data;
    })
  }


  edit(index: number) {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Modificar Personal", value: this.staffs[index].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.staffs[index].name = res;
        this.staffService.updateStaff(this.staffs[index]).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Personal modificado")
          }
        })
      }
    })
  }

  addStaff() {

    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nuevo Personal" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const staff: Staff = {
          name: res,
          personalType_uuid: "1",
        }
        this.staffService.addStaff(staff).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Personal creado");
            this.staffs.push(data.data);
          }
        })
      }
    })

  }

  deleteStaff(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "el personal " + this.staffs[index].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.staffService.deleteStaffById(this.staffs[index].uuid!).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Personal eliminado");
            this.staffs.splice(index, 1);
          }
        });
      }
    })
  }

  create(uuid: string, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Email del Personal" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.staffService.createUser(res, uuid).subscribe(data => {
          if (data.status == "ok") {
            this.dialog.open(ShowPasswordComponent, {data: {password: data.data.user?.password}})
            this.staffs.filter(s => s.uuid == uuid)[0].user = data.data.user;
            delete this.staffs.filter(s => s.uuid == uuid)[0].user?.password;
          }
        })
      }
    })
  }

  deactivate(uuid: string, event: Event){
    event.preventDefault();
    event.stopPropagation();
    this.staffService.deactivate(uuid).subscribe(data => {
      if (data.status == "ok") {
        this.openSnackBar("Usuario desactivado");
        this.staffs.filter(s => s.uuid == uuid)[0].user = data.data.user;
      }
    })
  }

  activate(uuid: string, event: Event){
    event.preventDefault();
    event.stopPropagation();
    this.staffService.activate(uuid).subscribe(data => {
      if (data.status == "ok") {
        this.openSnackBar("Usuario activado");
        this.staffs.filter(s => s.uuid == uuid)[0].user = data.data.user;
      }
    })
  }

  resetPassword(uuid: string, event: Event){
    event.preventDefault();
    event.stopPropagation();
    this.staffService.resetPassword(uuid).subscribe(data => {
      if (data.status == "ok") {
        this.dialog.open(ShowPasswordComponent, {data: {password: data.data.user?.password}})
      }
    });
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }
}
