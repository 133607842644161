<form [formGroup]="zoneForm">
    <mat-form-field appearance="outline">
        <mat-label>ZONA {{destination ? "DESTINO" : origin ? "ORIGEN" : ""}}</mat-label>
        <input type="text" matInput formControlName="zone_uuid" [matAutocomplete]="auto" (click)="clear()">
        <button
          mat-icon-button
          matSuffix
          (click)="clear()"
          [attr.aria-label]="'Clear'"
        >
          <mat-icon>close</mat-icon>
        </button>
        <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
          @for (option of zoneFilteredOptions | async; track option) {
          <mat-option [value]="option" (onSelectionChange)="send(option.uuid!)">{{option.name}}</mat-option>
          }
      </mat-autocomplete>
      <mat-error color="red">{{ errorMessage('zone_uuid','La zona es obligatoria') }}</mat-error>
    </mat-form-field>
</form>
