import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DiagReceived } from '../name-modal/name-modal.component';

@Component({
  selector: 'app-date-modal',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule
  ],
  templateUrl: './date-modal.component.html',
  styleUrl: './date-modal.component.css'
})
export class DateModalComponent {

  public dateForm: FormGroup = this.fb.group({
    date:[],
  })

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>) {
  }

  send(){
    this.dialogRef.close(this.dateForm.value.date)
  }



}
