<mat-dialog-content>
  <form [formGroup]="serviceForm" class="back">
    <app-location-autocomplete *ngIf="!private" class="mat-form-field origen" [location_uuid]="serviceForm.controls['origin_location_uuid'].value" [origin]="true" (selected_location)="assignOrigin($event)"></app-location-autocomplete>
    <app-location-autocomplete *ngIf="!private" class="mat-form-field destino" [location_uuid]="serviceForm.controls['destination_location_uuid'].value" [destination]="true" (selected_location)="assignDestination($event)"></app-location-autocomplete>
    <mat-form-field *ngIf="private" appearance="outline" class="mat-form-field origen">
      <mat-label>Origen</mat-label>
      <input type="text" formControlName="origin_location_private" matInput>
    </mat-form-field>
    <mat-form-field *ngIf="private" appearance="outline" class="mat-form-field destino">
      <mat-label>Destino</mat-label>
      <input type="text" formControlName="destination_location_private" matInput>
    </mat-form-field>
    <mat-form-field class="fechaIni" appearance="outline">
      <mat-label>Fecha Reserva</mat-label>
      <input matInput formControlName="date" [matDatepicker]="dpS">
      <mat-datepicker-toggle matIconSuffix [for]="dpS"></mat-datepicker-toggle>
      <mat-datepicker #dpS></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="horaIni" appearance="outline">
      <mat-label>Hora Reserva</mat-label>
      <input matInput placeholder="24hr format" aria-label="24hr format" formControlName="time" [ngxTimepicker]="fullTime" [format]="24"  readonly>
      <ngx-material-timepicker #fullTime></ngx-material-timepicker>
    </mat-form-field>
    <mat-form-field class="FechaFin" appearance="outline">
      <mat-label>Fecha Fin Reserva</mat-label>
      <input matInput formControlName="end_date" [matDatepicker]="dpSe">
      <mat-datepicker-toggle matIconSuffix [for]="dpSe"></mat-datepicker-toggle>
      <mat-datepicker #dpSe></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="horaFin" appearance="outline">
      <mat-label>Hora Fin Reserva</mat-label>
      <input matInput placeholder="24hr format" aria-label="24hr format" formControlName="endTime" [ngxTimepicker]="fullTimeE" [format]="24"  readonly>
      <ngx-material-timepicker #fullTimeE></ngx-material-timepicker>
    </mat-form-field>
    <app-staff-autocomplete class="mat-form-field personal" [staff_uuid]="serviceForm.value.driver_uuid" (selected_staff)="assignDriver($event)"></app-staff-autocomplete>
    <app-vehicle-autocomplete class="mat-form-field vehiculo" [vehicle_uuid]="serviceForm.value.vehicle_uuid" (selected_vehicle)="assignVehicles($event)"></app-vehicle-autocomplete>
    <mat-form-field appearance="outline" class="observaciones">
      <mat-label>Comentario</mat-label>
      <textarea formControlName="description" matInput> </textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button (click)="sendService()">{{serviceForm.value.uuid == "" ? "Crear" : "Editar"}}</button>
</mat-dialog-actions>
