import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { Router } from '@angular/router';
import { Passenger } from '../../interfaces/reservation';
import { PassengerService } from '../../services/passenger.service';
import { PassengerFormComponent } from '../passenger-form/passenger-form.component';

@Component({
  selector: 'app-passenger',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule
  ],
  templateUrl: './passenger.component.html',
  styleUrl: './passenger.component.css'
})
export class PassengerComponent implements OnInit, OnChanges{
  @Input() field: string = "0";
  @Input() private: boolean = false;
  displayedColumns: string[] = ['hour', 'pax', 'childs', 'babys', 'location', 'description', 'name', 'flight', 'flightDate', 'action'];
  passengerList: Passenger[] = [];
  dataSource = new MatTableDataSource(this.passengerList);

  constructor(private passengerService: PassengerService, public dialog: MatDialog, private router: Router){

  }

  ngOnInit(){
    this.getPassengerByReservation();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['private'] && !changes['private'].firstChange) {
      this.getPassengerByReservation();
    }
  }

  getPassengerByReservation(){
    this.passengerService.getAllPassengerByReservation(this.field).subscribe(data => {
      if(data.status == "ok"){
        this.passengerList = data.data;
        this.dataSource = new MatTableDataSource(this.passengerList);
      }
    })
  }

  sendPassenger(passenger?: Passenger){
    if(passenger == null){
      passenger = {} as Passenger;
      passenger.reservation_uuid = this.field;
    }
    const dialogRef = this.dialog.open(PassengerFormComponent, {
      data: { object: this.private, value: passenger },
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        passenger = res;
        if(!passenger!.uuid){
          this.passengerService.addPassenger(passenger!).subscribe(data => {
            if(data.status == "ok"){
              this.getPassengerByReservation();
            }else{
              this.sendPassenger(passenger);
            }
          })
        }else{
          this.passengerService.updatePassenger(passenger!).subscribe(data => {
            if(data.status == "ok"){
              this.getPassengerByReservation();
            }else{
              this.sendPassenger(passenger);
            }
          })
        }
      }
    });
  }

  removePassenger(passenger: Passenger){
    this.passengerService.deletePassengerById(passenger.uuid!).subscribe(data => {
      if(data.status == "ok"){
        this.getPassengerByReservation();
      }
    })
  }

}
