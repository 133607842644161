import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Price } from '../interfaces/price';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getAllPriceByZone(id: string):Observable<ApiListResponse<Price>>{
    return this.http.get<ApiListResponse<Price>>(this.apiUrl + "rate/zone/" + id)
  }

  getPriceById(id: string):Observable<ApiResponse<Price>>{
    return this.http.get<ApiResponse<Price>>(this.apiUrl + "rate/" + id)
  }

  addPrice(price: Price):Observable<ApiResponse<Price>>{
    return this.http.post<ApiResponse<Price>>(this.apiUrl + "rate", price)
  }

  updatePrice(price: Price):Observable<ApiResponse<Price>>{
    return this.http.put<ApiResponse<Price>>(this.apiUrl + "rate/" + price.uuid, price)
  }

  deletePriceById(id: string):Observable<ApiResponse<Price>>{
    return this.http.delete<ApiResponse<Price>>(this.apiUrl + "rate/" + id)
  }
}
