import { AsyncPipe, CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Invoice_Row } from '../../interfaces/invoicerow';
import { ReservationInvoice } from '../../interfaces/reservation';
import { InvoiceService } from '../../services/invoice.service';
import { ReservationService } from '../../services/reservation.service';
import { AgencyAutocompleteComponent } from '../autocomplete/agency-autocomplete/agency-autocomplete.component';
import { StaffAutocompleteComponent } from '../autocomplete/staff-autocomplete/staff-autocomplete.component';
import { SupplierAutocompleteComponent } from '../autocomplete/supplier-autocomplete/supplier-autocomplete.component';
import { TouroperatorAutocompleteComponent } from '../autocomplete/touroperator-autocomplete/touroperator-autocomplete.component';
import { VehicleAutocompleteComponent } from '../autocomplete/vehicle-autocomplete/vehicle-autocomplete.component';
import { PassengerComponent } from '../passenger/passenger.component';
import { ServiceComponent } from '../service/service.component';
import { ServiceFormComponent } from '../services-form/services-form.component';


@Component({
  selector: 'app-invoice',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatAutocompleteModule,
    AsyncPipe,
    MatDatepickerModule,
    ServiceFormComponent,
    PassengerComponent,
    MatSelectModule,
    MatCheckboxModule,
    ServiceComponent,
    MatTooltipModule,
    TouroperatorAutocompleteComponent,
    SupplierAutocompleteComponent,
    AgencyAutocompleteComponent,
    StaffAutocompleteComponent,
    VehicleAutocompleteComponent,
    MatTableModule,
  ],
  templateUrl: './statistics.component.html',
  styleUrl: './statistics.component.css'
})
export class StatisticsComponent implements OnInit{

  reservationList: ReservationInvoice[] = [];

  stadisticForm = this.fb.group({
    touroperator_uuid: [""],
    supplier_uuid: [""],
    agency_uuid: [""],
    staff_uuid: [""],
    vehicle_uuid: [""],
    from: [new Date, [Validators.required]],
    to: [new Date, [Validators.required]],
  });

  invocie_row_add: string[] = [];

  displayedColumns: string[] = ['reservation', 'date', 'service', 'driver', 'price'];
  dataSource = new MatTableDataSource(this.reservationList);

  constructor(private invoiceService: InvoiceService,  private fb: FormBuilder, private reservationService: ReservationService, private _snackBar: MatSnackBar, private router: Router){}

  ngOnInit() {

  }

  getReservations(){
    this.invoiceService.getReservations(this.stadisticForm.value.supplier_uuid!, this.stadisticForm.value.agency_uuid!, this.stadisticForm.value.touroperator_uuid!, this.stadisticForm.value.staff_uuid!, this.stadisticForm.value.vehicle_uuid!, this.stadisticForm.value.from!, this.stadisticForm.value.to!).subscribe(data => {
      if(data.status == "ok"){
        this.reservationList = data.data;
        this.dataSource = new MatTableDataSource(this.reservationList);
      }
    })
  }

  getTouroperators(uuid: string) {
    this.stadisticForm.patchValue({
      touroperator_uuid: "",
      agency_uuid: uuid
    });
  }

  assignTtoo(uuid: string) {
    this.stadisticForm.patchValue({
      touroperator_uuid: uuid
    });
  }

  assignS(uuid: string) {
    this.stadisticForm.patchValue({
      supplier_uuid: uuid
    });
  }

  assignSt(uuid: string) {
    this.stadisticForm.patchValue({
      staff_uuid: uuid
    });
  }

  assignV(uuid: string) {
    this.stadisticForm.patchValue({
      vehicle_uuid: uuid
    });
  }

  edit(reservation: ReservationInvoice){
    reservation.edit = !reservation.edit;
  }

  editReservation(invoice_row: Invoice_Row){
    if(!invoice_row.invoice_uuid){
      delete invoice_row.invoice_uuid;
    }
    this.reservationService.updateInvoice(invoice_row).subscribe(data => {
      if (data.status == "ok") {

      }
    })
  }

  add(uuid: string){
    let index = this.invocie_row_add.indexOf(uuid);
    if(index == -1){
      this.invocie_row_add.push(uuid);
    }else{
      this.invocie_row_add.splice(index, 1);
    }
  }

  addInvoice(){
    this.invoiceService.addInvoice(this.invocie_row_add).subscribe(data => {
      this.router.navigate(["invoiceList"])
    })
  }

  getTotalPrice() {
    const total = this.reservationList
    .map(t => t.invoice_row.total)
    .reduce((acc, value) => acc + value, 0);

    return parseFloat(total.toFixed(2));
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }
}
