import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, map, startWith } from 'rxjs';
import { Direction } from '../../../interfaces/direction';
import { Zone } from '../../../interfaces/zone';
import { DirectionService } from '../../../services/direction.service';
import { ZoneService } from '../../../services/zone.service';
import { NameModalComponent } from '../name-modal/name-modal.component';

export interface DiagReceived { object: string };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };

@Component({
  selector: 'app-zone-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatAutocompleteModule,
    MatDialogModule
  ],
  templateUrl: './zone-modal.component.html',
  styleUrl: './zone-modal.component.css'
})
export class ZoneModalComponent implements OnInit {

  public zoneForm: FormGroup = this.fb.group({
    uuid: [""],
    name: [""],
    order: [""],
    direction: [""],
    direction_uuid: [""]
  })
  directions: Direction[] = [];
  directionFilteredOptions: Observable<Direction[]> = new Observable<Direction[]>();

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>, private zoneService: ZoneService,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private directionService: DirectionService, private _snackBar: MatSnackBar, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.directionFilteredOptions = this.zoneForm.controls['direction'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterD(name as string) : this.directions;
      }),
    );
    this.getDirections();
    if (!!this.data.object) {
      this.getZone();
    }
  }

  getZone() {
    this.zoneService.getZoneById(this.data.object).subscribe(data => {
      this.zoneForm.patchValue({
        uuid: data.data.uuid,
        name: data.data.name,
        order: data.data.order,
        direction: data.data.direction,
        direction_uuid: data.data.direction_uuid
      });
    })
  }

  getDirections() {
    this.directionService.getAllDirections().subscribe(data => {
      this.directions = data;
    })
  }

  displayD(direction: Direction): string {
    return direction && direction.name ? direction.name : '';
  }

  private _filterD(name: string): Direction[] {
    if (name != "" && this.directions.length > 0) {
      const filterValue = name.toLowerCase();
      return this.directions.filter(a => a.name?.toLowerCase().includes(filterValue));
    } else {
      return this.directions;
    }

  }

  addDirection() {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nueva Dirección" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const direction: Direction = {
          name: res,
        }
        this.directionService.addDirection(direction).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Dirección creada");
            this.directions.push(data.data);
          }
        })
      }
    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }

  send() {
    const newZone: Zone = {
      name: this.zoneForm.value.name,
      order: this.zoneForm.value.order,
      direction: this.zoneForm.value.direction,
      direction_uuid: this.zoneForm.value.direction.uuid
    }
    this.dialogRef.close(newZone)
  }

}
