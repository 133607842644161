import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable, map, startWith } from 'rxjs';
import { Agency } from '../../../interfaces/agency';
import { Touroperator } from '../../../interfaces/touroperator';
import { AgencyService } from '../../../services/agency.service';

export interface DiagReceived { object: string, value: string };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };

@Component({
  selector: 'app-ttoo-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatDialogModule
  ],
  templateUrl: './ttoo-modal.component.html',
  styleUrl: './ttoo-modal.component.css'
})
export class TtooModalComponent implements OnInit {

  agencies: Agency[] = [];
  agencyFilteredOptions: Observable<Agency[]> = new Observable<Agency[]>();
  public ttooForm: FormGroup = this.fb.group({
    agency: [],
    name: []
  })

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private agencyService: AgencyService) {
  }

  ngOnInit() {
    this.agencyFilteredOptions = this.ttooForm.controls['agency'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterA(name as string) : this.agencies;
      }),
    );

    this.getAgencies();
  }

  getAgencies() {
    this.agencyService.getAllAgencies().subscribe(data => {
      this.agencies = data;
    })
  }

  displayAg(agency: Agency): string {
    return agency && agency.name ? agency.name : '';
  }

  private _filterA(name: string): Agency[] {
    if (name != "" && this.agencies.length > 0) {
      const filterValue = name.toLowerCase();
      return this.agencies.filter(a => a.name?.toLowerCase().includes(filterValue));
    } else {
      return this.agencies;
    }

  }

  send() {
    const newTtoo: Touroperator = {
      name: this.ttooForm.value.name,
      agency_uuid: this.ttooForm.value.agency.uuid
    }
    this.dialogRef.close(newTtoo)
  }
}
