import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private currentTitle: BehaviorSubject<string> = new BehaviorSubject<string>("Reservas de coches");
  constructor() { }

  public setTitle(title:string){
    this.currentTitle.next(title);
  }

  public getTitle():Observable<string> {
    return this.currentTitle.asObservable();
  }

}
