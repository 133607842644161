<mat-dialog-content>
    <app-supplier-autocomplete *ngIf="data.type=='supplier'" [supplier_uuid]="data.supplier_uuid"
        (selected_supplier)="assingSupplier($event)"></app-supplier-autocomplete>
    <app-staff-autocomplete *ngIf="data.type=='staff'" [staff_uuid]="data.driver_uuid"
        (selected_staff)="assingDriver($event)"></app-staff-autocomplete>
    <app-vehicle-autocomplete *ngIf="data.type=='vehicle'" [vehicle_uuid]="data.vehicle_uuid"
        (selected_vehicle)="assingVehicle($event)"></app-vehicle-autocomplete>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="send()">Modificar</button>
</mat-dialog-actions>
