import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { Supplier } from '../../interfaces/supplier';
import { SupplierService } from '../../services/supplier.service';
import { TitleService } from '../../services/title.service';
import { TouroperatorService } from '../../services/touroperator.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { SupplierModalComponent } from '../modal/supplier-modal/supplier-modal.component';
@Component({
  selector: 'app-supplier',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    MatTooltipModule,
    MatListModule,
    MatCardModule
  ],
  templateUrl: './supplier.component.html',
  styleUrl: './supplier.component.css'
})
export class SupplierComponent implements OnInit {
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;

  public loadingSuppliers: boolean = true;

  public suppliers: Array<Supplier> = new Array<Supplier>();
  private suppliersChangedSubscription: Subscription = {} as Subscription;
  constructor(private supplierService: SupplierService, private touroperatorService: TouroperatorService, private _snackBar: MatSnackBar, public dialog: MatDialog, private _title: TitleService) {
    _title.setTitle("Proveedores") }
  ngOnInit() {
    this.getAllSuppliers();
    this.suppliersChangedSubscription = this.supplierService.suppliersChanged.subscribe({next: (net) => {
      if(net){
        this.getAllSuppliers();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.suppliersChangedSubscription) {
      this.suppliersChangedSubscription.unsubscribe();
    }
  }

  getAllSuppliers() {
    this.loadingSuppliers = true;
    this.supplierService.getAllSuppliers().subscribe(data => {
      this.suppliers = data;
      this.loadingSuppliers = false;
    })
  }


  edit(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(SupplierModalComponent, {
      data: { object: "Modificar Proveedor", value: this.suppliers[index].uuid },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.suppliers[index] = res;
        this.supplierService.updateSupplier(this.suppliers[index]).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Proveedor modificado")
          }
        })
      }
    })
  }

  addSupplier(supp: Supplier = {} as Supplier, error?:any) {
    const dialogRef = this.dialog.open(SupplierModalComponent, {
      data: { object: "Nuevo Proveedor", supplier: supp, error: error },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const supplier: Supplier = res
        this.supplierService.addSupplier(supplier).subscribe({next: (data) => {
          if (data.status == "ok") {
            this.openSnackBar("Proveedor creado");
            this.suppliers.push(data.data);
          }
        },
        error: (error) => {
          this.addSupplier(res, error);
          this.openSnackBar("El proveedor no se ha podido crear revise los campos.");
        }
      })
      }
    })

  }

  deleteSupplier(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "el vehiculo " + this.suppliers[index].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.supplierService.deleteSupplierById(this.suppliers[index].uuid!).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Proveedor eliminado");
            this.suppliers.splice(index, 1);
          }
        });
      }
    })

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",

      duration: 5000
    });
  }
}
