import { Injectable } from '@angular/core';
import Echo from 'laravel-echo';

import { MatSnackBar } from '@angular/material/snack-bar';
import Pusher from 'pusher-js';
import { Subject } from 'rxjs';
import { BaseService } from './base.service';
import { LoginService } from './login.service';
import { SecureStorageService } from './secureStorage.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService extends BaseService {

  private echo : Echo = {} as Echo;
  private pusher: Pusher = {} as Pusher;
  private token = "";
  private dataObsr: Subject<string> = new Subject<string>();
  constructor(private secureStorageService: SecureStorageService, private _snackBar: MatSnackBar, private loginService: LoginService) {
    super();
    //@ts-ignore
    window["Pusher"] = Pusher;
  }

  initial(){
    this.token = this.secureStorageService.get('token');
    this.echo = new Echo({
      auth: {
        headers: {
          Authorization: "Bearer " + this.token,
          Accept: "application/json"
        }
      },
      authorizer: (channel: any, options: any) => {
        return {
          authorize: (socketId: any, callback: any) => {
            fetch(this.apiUrl + 'broadcasting/auth', {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.token
              },
              body: JSON.stringify({
                socket_id: socketId,
                channel_name: channel.name
              })
            })
              .then(response => response.json())
              .then(data => {
                callback(false, data);
              })
              .catch((error) => {
                callback(true, error);
              });
          }
        };
      },
      broadcaster: 'reverb',
      key: this.wsConfig.key,
      wsHost: this.apiUrl.split('/')[2].split(':')[0],
      wsPort: this.wsConfig.wsPort,
      wssPort: this.wsConfig.wssPort,
      forceTLS: this.wsConfig.forcetls,
      enabledTransports: ['ws','wss'],
    });

    this.getData();
    this.getMails();
  }

  getData(){
    this.echo.private(`updates`)
    .listen('NeedUpdate', (e: any) => {
      this.dataObsr.next(e.update);
    });
  }

  getMails(){
    this.echo.private('mails.' + this.loginService.getUser().uuid)
    .listen('MailSended', (e: any) => {
      this.openSnackBar("La factura " + e.invoice + " se ha enviado.");
    })
  }

  delete(){
    this.echo.leaveAllChannels();
    this.echo.disconnect();
  }

  get dataObs() {
    return this.dataObsr.asObservable();
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }

}
