import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../interfaces/ApiResponse';
import { Invoice_Row } from '../interfaces/invoicerow';
import { Reservation } from '../interfaces/reservation';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getReservationById(id: string):Observable<ApiResponse<Reservation>>{
    return this.http.get<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + id)
  }

  getFollowingReservation(id: string):Observable<ApiResponse<Reservation>>{
    return this.http.get<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + id + "/next")
  }

  getPreviousReservation(id: string):Observable<ApiResponse<Reservation>>{
    return this.http.get<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + id + "/previous")
  }

  addReservation(reservation: Reservation):Observable<ApiResponse<Reservation>>{
    return this.http.post<ApiResponse<Reservation>>(this.apiUrl + "reservation", reservation)
  }

  updateReservation(reservation: Reservation):Observable<ApiResponse<Reservation>>{
    return this.http.put<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + reservation.uuid, reservation)
  }

  updateInvoice(invoice: Invoice_Row):Observable<ApiResponse<Invoice_Row>>{
    return this.http.put<ApiResponse<Invoice_Row>>(this.apiUrl + "invoice_row/" + invoice.uuid, invoice)
  }

  deleteReservationById(id: string):Observable<ApiResponse<Reservation>>{
    return this.http.delete<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + id)
  }
}
