import { Injectable } from '@angular/core';
import { EncryptStorage } from 'encrypt-storage';
import { BaseService } from './base.service';


@Injectable({
  providedIn: 'root'
})
export class SecureStorageService extends BaseService {

  encStorage = new EncryptStorage(this.secretKey, {storageType: 'localStorage'});

  constructor() {
    super();
   }

  public set(key: string, value: any) {
    this.encStorage.setItem(key, value);
  }

  public get(key: string): any {
    return this.encStorage.getItem(key);
  }

  public clear(key: string) {
    this.encStorage.removeItem(key);
  }

}
