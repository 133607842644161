import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Touroperator } from '../../../interfaces/touroperator';
import { TouroperatorService } from '../../../services/touroperator.service';

@Component({
  selector: 'app-touroperator-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './touroperator-autocomplete.component.html',
  styleUrl: './touroperator-autocomplete.component.css'
})
export class TouroperatorAutocompleteComponent implements OnInit, OnDestroy {
  @Input() agency_uuid: string = "";
  @Input() touroperator_uuid: string = "";
  @Output() selected_touroperator = new EventEmitter<string>();
  touroperators: Touroperator[] = [];
  private touroperatorsChangedSubscription: Subscription = {} as Subscription;
  touroperatorFilteredOptions: Observable<Touroperator[]> = new Observable<Touroperator[]>();
  public touroperatorForm: FormGroup = this.fb.group({
    touroperator_uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
  })

  constructor(private touroperatorService: TouroperatorService, private fb: FormBuilder) { }

  ngOnInit() {
    this.touroperatorFilteredOptions = this.touroperatorForm.controls['touroperator_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterT(name as string) : this.touroperators;
      }),
    );
    this.getTouroperators(true);
    this.touroperatorsChangedSubscription = this.touroperatorService.touroperatorsChanged.subscribe({next: (net) => {
      if(net){
        this.getTouroperators();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.touroperatorsChangedSubscription) {
      this.touroperatorsChangedSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agency_uuid'] && this.agency_uuid != "" && !changes['agency_uuid'].firstChange) {
      this.touroperatorForm.patchValue({
        touroperator_uuid: ""
      });
      this.touroperators = [];
      this.getTouroperators(true);
    }

    if (changes['touroperator_uuid']) {
        this.getTouroperators(true);
    }
  }

  getTouroperators(search: boolean = false) {
    this.touroperatorService.getAllTouroperators().subscribe(data => {
      this.touroperators = !!this.agency_uuid ? data.filter(t => t.agency_uuid == this.agency_uuid) : data;
      if(search){
        this.touroperatorForm.patchValue({
          touroperator_uuid: this.touroperators.filter(a => a.uuid == this.touroperator_uuid)[0]
        });
      }
    })
  }

  getTouroperatorsList() {
    return this.touroperatorForm.controls['touroperator_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filterT(name as string) : this.touroperators;
      }),
    );
  }

  displayT(touroperator: Touroperator): string {
    return touroperator && touroperator.name ? touroperator.name : '';
  }

  private _filterT(name: string): Touroperator[] {
    const filterValue = name.toLowerCase();
    return this.touroperators.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_touroperator.emit(uuid);
  }

  recargar() {
    this.getTouroperators();
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.touroperatorForm.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  clear(){
    this.touroperatorForm.patchValue({
      touroperator_uuid: ""
    });
    this.selected_touroperator.emit();
  }
}
