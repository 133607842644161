  <mat-dialog-content>
    <form [formGroup]="passengerForm" class="back">
      <mat-form-field appearance="outline" class="nombre">
        <mat-label>Nombre</mat-label>
        <input formControlName="name" matInput type="text">
        <mat-error color="red">{{ errorMessage('name','El nombre es obligatorio') }}</mat-error>
      </mat-form-field>
      <app-location-autocomplete *ngIf="!private" class="mat-form-field hotel" [location_uuid]="passengerForm.controls['location_uuid'].value" (selected_location)="assignLocation($event)"></app-location-autocomplete>
      <mat-form-field *ngIf="private" appearance="outline" class="mat-form-field hotel">
        <mat-label>Hotel</mat-label>
        <input type="text" formControlName="location_private" matInput>
      </mat-form-field>
      <mat-form-field appearance="outline" class="vuelo">
        <mat-label>Vuelo</mat-label>
        <input formControlName="flight" matInput type="text">
        <mat-error color="red">{{ errorMessage('flight','El numero de vuelo es obligatorio') }}</mat-error>
      </mat-form-field>
      <mat-form-field class="" appearance="outline" class="fechaVuelo">
        <mat-label>Fecha Vuelo</mat-label>
        <input matInput formControlName="flight_date" [matDatepicker]="dpP">
        <mat-datepicker-toggle matIconSuffix [for]="dpP"></mat-datepicker-toggle>
        <mat-datepicker #dpP></mat-datepicker>
      </mat-form-field>
      <mat-form-field class="horaVuelo" appearance="outline">
        <mat-label>Hora Reserva</mat-label>
        <input matInput placeholder="24hr format" aria-label="24hr format" formControlName="time" [ngxTimepicker]="fullTime" [format]="24"  readonly>
        <ngx-material-timepicker #fullTime></ngx-material-timepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" class="pax">
        <mat-label>Pasajeros</mat-label>
        <input formControlName="pax" matInput type="number" step="1">
      </mat-form-field>
      <mat-form-field appearance="outline" class="ninios">
        <mat-label>Niños</mat-label>
        <input formControlName="childs" matInput type="number" step="1">
      </mat-form-field>
      <mat-form-field appearance="outline" class="bebes">
        <mat-label>Bebés</mat-label>
        <input formControlName="babies" matInput type="number" step="1">
      </mat-form-field>
      <mat-form-field appearance="outline" class="observaciones">
        <mat-label>Comentario</mat-label>
        <textarea formControlName="description" matInput></textarea>
      </mat-form-field>
      <span class="spacer"></span>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button (click)="sendPassenger()">{{passengerForm.value.uuid == "" ? "Crear" : "Editar"}}</button>
  </mat-dialog-actions>
