<div class="hv">
    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Buscador de reservas</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <form [formGroup]="stadisticForm" class="container">
                <app-supplier-autocomplete class="mat-form-field mt-2"
                    [supplier_uuid]="stadisticForm.controls['supplier_uuid'].value!"
                    (selected_supplier)="assignS($event)"></app-supplier-autocomplete>
                <app-agency-autocomplete class="mat-form-field mt-2"
                    [agency_uuid]="stadisticForm.controls['agency_uuid'].value!"
                    (selected_agency)="getTouroperators($event)"></app-agency-autocomplete>
                <app-touroperator-autocomplete class="mat-form-field mt-2"
                    [agency_uuid]="stadisticForm.controls['agency_uuid'].value!"
                    [touroperator_uuid]="stadisticForm.controls['touroperator_uuid'].value!"
                    (selected_touroperator)="assignTtoo($event)"></app-touroperator-autocomplete>
                <app-staff-autocomplete class="mat-form-field mt-2"
                    [staff_uuid]="stadisticForm.controls['staff_uuid'].value!"
                    (selected_staff)="assignSt($event)"></app-staff-autocomplete>
                <app-vehicle-autocomplete class="mat-form-field mt-2"
                    [vehicle_uuid]="stadisticForm.controls['vehicle_uuid'].value!"
                    (selected_vehicle)="assignV($event)"></app-vehicle-autocomplete>
                <mat-form-field class="date mr-2 mt-2" appearance="outline">
                    <mat-label>FECHA INICIO</mat-label>
                    <input matInput [matDatepicker]="pickerF" class="date" formControlName="from">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerF"></mat-datepicker-toggle>
                    <mat-datepicker #pickerF></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="date mt-2" appearance="outline">
                    <mat-label>FECHA FIN</mat-label>
                    <input matInput [matDatepicker]="pickerT" class="date" formControlName="to">
                    <mat-datepicker-toggle matIconSuffix [for]="pickerT"></mat-datepicker-toggle>
                    <mat-datepicker #pickerT></mat-datepicker>
                </mat-form-field>
            </form>
        </mat-card-content>
        <mat-card-actions>
            <button class="button" mat-stroked-button type="button" (click)="getReservations()">
                <mat-icon>search</mat-icon>
                Buscar Reservas
            </button>
        </mat-card-actions>
    </mat-card>

    <mat-card class="mv">
        <mat-card-header>
            <mat-card-title>Reservas</mat-card-title>
        </mat-card-header>
        <mat-card-content class="overflowScroll table">
            <table *ngIf="reservationList.length != 0" mat-table [dataSource]="dataSource">
                <!-- Position Column -->

                <ng-container matColumnDef="reservation">
                    <th mat-header-cell *matHeaderCellDef> Referencia </th>
                    <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
                    <td mat-footer-cell *matFooterCellDef> Total </td>
                </ng-container>

                <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef> FECHA </th>
                    <td mat-cell *matCellDef="let element"> {{element.date | date:'dd/MM/yyyy'}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="price">
                    <th mat-header-cell *matHeaderCellDef> PRECIO </th>
                    <td mat-cell *matCellDef="let element">
                        <span *ngIf="!element.edit">{{element.invoice_row.total}}</span>
                        <!-- <input *ngIf="!!element.edit" class="price" matInput type="number" step="1" [(ngModel)]="element.invoice_row.total"> -->
                    </td>
                    <td mat-footer-cell *matFooterCellDef> {{getTotalPrice()}} </td>
                </ng-container>

                <ng-container matColumnDef="service">
                    <th mat-header-cell *matHeaderCellDef> Servicio </th>
                    <td mat-cell *matCellDef="let element"> {{element.service[0].description}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>

                <ng-container matColumnDef="driver">
                    <th mat-header-cell *matHeaderCellDef> Conductor </th>
                    <td mat-cell *matCellDef="let element"> {{element.service[0].driver?.name}} </td>
                    <td mat-footer-cell *matFooterCellDef> </td>
                </ng-container>


                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>