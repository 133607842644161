import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { Vehicle } from '../../interfaces/vehicle';
import { TitleService } from '../../services/title.service';
import { TouroperatorService } from '../../services/touroperator.service';
import { VehicleService } from '../../services/vehicle.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { NameModalComponent } from '../modal/name-modal/name-modal.component';
@Component({
  selector: 'app-vehicle',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    MatTooltipModule
  ],
  templateUrl: './vehicle.component.html',
  styleUrl: './vehicle.component.css'
})
export class VehicleComponent implements OnInit {
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;

  public vehicles: Array<Vehicle> = new Array<Vehicle>();
  private vehiclesChangedSubscription: Subscription = {} as Subscription;
  constructor(private vehicleService: VehicleService, private touroperatorService: TouroperatorService, private _snackBar: MatSnackBar, public dialog: MatDialog, private _title: TitleService) {
    _title.setTitle("Vehículos") }
  ngOnInit() {
    this.getAllVehicles();
    this.vehiclesChangedSubscription = this.vehicleService.vehiclesChanged.subscribe({next: (net) => {
      if(net){
        this.getAllVehicles();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.vehiclesChangedSubscription) {
      this.vehiclesChangedSubscription.unsubscribe();
    }
  }

  getAllVehicles() {
    this.vehicleService.getAllVehicles().subscribe(data => {
      this.vehicles = data;
    })
  }


  edit(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Modificar Vehiculo", value: this.vehicles[index].license_plate },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.vehicles[index].license_plate = res;
        this.vehicleService.updateVehicle(this.vehicles[index]).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Vehiculo modificado")
          }
        })
      }
    })
  }

  addVehicle() {

    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nuevo Vehiculo" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const vehicle: Vehicle = {
          license_plate: res
        }
        this.vehicleService.addVehicle(vehicle).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Vehiculo creado");
            this.vehicles.push(data.data);
          }
        })
      }
    })

  }

  deleteVehicle(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "el vehiculo " + this.vehicles[index].license_plate },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.vehicleService.deleteVehicleById(this.vehicles[index].uuid!).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Vehiculo eliminado");
            this.vehicles.splice(index, 1);
          }
        });
      }
    })

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",

      duration: 5000
    });
  }
}
