import { CommonModule } from '@angular/common';
import { Component, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { Location } from '../../interfaces/location';
import { Zone } from '../../interfaces/zone';
import { LocationService } from '../../services/location.service';
import { TitleService } from '../../services/title.service';
import { ZoneService } from '../../services/zone.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { NameModalComponent } from '../modal/name-modal/name-modal.component';
import { ZoneModalComponent } from '../modal/zone-modal/zone-modal.component';
import { RateComponent } from '../rate/rate.component';

@Component({
  selector: 'app-zone',
  standalone: true,
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatTooltipModule,
    MatCardModule
  ],
  templateUrl: './zone.component.html',
  styleUrl: './zone.component.css'
})
export class ZoneComponent {
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion;
  cargandoZonas: boolean = false;
  public zones: Array<Zone> = new Array<Zone>();
  private zonesChangedSubscription: Subscription = {} as Subscription;
  public loadingZones: boolean = true;

  constructor(private zoneService: ZoneService, private locationService: LocationService, private _snackBar: MatSnackBar, public dialog: MatDialog, private _title: TitleService) {
    _title.setTitle("Zonas") }
  ngOnInit() {
    this.getAllZones();
    this.zonesChangedSubscription = this.zoneService.zonesChanged.subscribe({next: (net) => {
      if(net){
        this.getAllZones();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.zonesChangedSubscription) {
      this.zonesChangedSubscription.unsubscribe();
    }
  }

  locations(zone: Zone) {

    return (zone.location) ? zone.location.length : 0;
  }

  getAllZones() {
    this.loadingZones = true;
    this.zoneService.getAllZones().subscribe(data => {
      this.zones = data;
      this.loadingZones = false;
    })
  }


  edit(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(ZoneModalComponent, {
      data: { object: this.zones[index].uuid },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.zones[index].name = res.name;
        this.zones[index].order = res.order;
        this.zones[index].direction = res.direction;
        this.zones[index].direction_uuid = res.direction.uuid;
        this.zoneService.updateZone(this.zones[index]).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Zona modificada")
            this.zones.push(data.data);
          }
        })
      }
    })

  }

  editLocation(index: number, indexL: number) {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Modificar punto de recogida ", value: this.zones[index].location![indexL].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        this.zones[index].location![indexL].name = res;
        this.locationService.updateLocation(this.zones[index].location![indexL]).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Punto de recogida modificado")
            this.zones[index].location!.push(data.data);
          }
        })
      }
    })
  }

  getZoneById(index: number) {
    this.zoneService.getZoneById(this.zones[index].uuid!).subscribe(data => {
      if (data.status == "ok") {
        this.zones[index].location = data.data.location;
      }
    })
  }

  addZone() {
    const dialogRef = this.dialog.open(ZoneModalComponent, {
      data: {},
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const zone: Zone = {
          name: res.name,
          order: res.order,
          direction_uuid: res.direction_uuid
        }
        this.zoneService.addZone(zone).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Zona creada")
            this.zones.push(data.data);
          }
        })
      }
    })
  }

  addLocation(index: number) {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nuevo punto de recogida" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const location: Location = {
          name: res,
          zone_uuid: this.zones[index].uuid
        }
        this.locationService.addLocation(location).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Punto de recogida creada")
            this.zones[index].location?.push(data.data);
          }
        })
      }

    })
  }

  showPrice(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(RateComponent, {
      maxWidth: "none",
      minHeight: "1vh",
      maxHeight: "50vh",
      data: { object: this.zones[index].uuid, value: this.zones[index].order },
    })
  }

  deleteLocation(index: number, indexT: number) {
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "el punto de recogida " + this.zones[index].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.locationService.deleteLocationById(this.zones[index].location![indexT].uuid!).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Punto de recogida eliminado");
            this.zones[index].location?.splice(indexT, 1);
          }
        });
      }
    })
  }

  deleteZone(index: number, event: Event){
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "la zona " + this.zones[index].name },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.zoneService.deleteZoneById(this.zones[index].uuid!).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Zona eliminada");
            this.zones.splice(index, 1);
          }
        });
      }
    })

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
      duration: 5000
    });
  }
}
