<div class="hv">
  <mat-card class="mv">
    <mat-card-header>
      <mat-card-title>
        <button *ngIf="field != '0'" mat-icon-button type="button" (click)="previous()">
          <mat-icon fontSet="material-icons-round">chevron_left</mat-icon>
        </button>
        Datos generales 
        <button *ngIf="field != '0'" mat-icon-button type="button" (click)="following()">
          <mat-icon fontSet="material-icons-round">navigate_next</mat-icon>
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="reservationForm" class="container">
        <app-supplier-autocomplete class="mat-form-field supplier"
          [supplier_uuid]="reservationForm.controls['supplier_uuid'].value"
          (selected_supplier)="assignS($event)"></app-supplier-autocomplete>
        <app-agency-autocomplete class="mat-form-field agency" [agency_uuid]="agency"
          (selected_agency)="getTouroperators($event)"></app-agency-autocomplete>
        <app-touroperator-autocomplete class="mat-form-field touroperator" [agency_uuid]="agency"
          [touroperator_uuid]="reservationForm.controls['touroperator_uuid'].value"
          (selected_touroperator)="assignTtoo($event)"></app-touroperator-autocomplete>
        <mat-form-field class="cliref" appearance="outline">
          <mat-label>REFERENCIA CLIENTE</mat-label>
          <input formControlName="customer_reference" matInput type="text">
        </mat-form-field>
        <app-zone-autocomplete class="mat-form-field zone"
          [zone_uuid]="reservationForm.controls['origin_zone_uuid'].value" [origin]="true"
          (selected_zone)="assignOrigin($event)"></app-zone-autocomplete>
        <app-zone-autocomplete class="mat-form-field destZone"
          [zone_uuid]="reservationForm.controls['destination_zone_uuid'].value" [destination]="true"
          (selected_zone)="assignDestination($event)"></app-zone-autocomplete>
        <mat-form-field class="pax" appearance="outline">
          <mat-label>PAX</mat-label>
          <input formControlName="pax" matInput type="number">
        </mat-form-field>
        <mat-form-field class="date" appearance="outline">
          <mat-label>FECHA</mat-label>
          <input matInput [matDatepicker]="picker" class="date" formControlName="date">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error color="red">{{ errorMessage('date','La fecha es obligatoria') }}</mat-error>
        </mat-form-field>
        <mat-form-field class="time" class="example-form-field" appearance="outline">
          <mat-label>HORA RESERVA</mat-label>
          <input matInput placeholder="24hr format" aria-label="24hr format" formControlName="time"
            [ngxTimepicker]="fullTime" [format]="24" readonly>
          <ngx-material-timepicker #fullTime></ngx-material-timepicker>
        </mat-form-field>
        <mat-form-field class="maxi" appearance="outline">
          <mat-label>MAXICOSI</mat-label>
          <input formControlName="maxi_cosi" matInput type="number">
        </mat-form-field>
        <mat-form-field class="bebe" appearance="outline">
          <mat-label>SILLAS CON CINTURON</mat-label>
          <input formControlName="baby_chair" matInput type="number">
        </mat-form-field>
        <mat-form-field class="alza" appearance="outline">
          <mat-label>ALZADOR</mat-label>
          <input formControlName="baby_booster" matInput type="number">
        </mat-form-field>

        <mat-form-field class="type" appearance="outline">
          <mat-label>TIPO</mat-label>
          <mat-select formControlName="agp">
            <mat-option value=""></mat-option>
            <mat-option value="in">Entrada</mat-option>
            <mat-option value="out">Salida</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- <mat-form-field class="" appearance="outline">
        <mat-label>Tipo</mat-label>
        <mat-select formControlName="agp">
          <mat-option></mat-option>
          <mat-option (selected)="reservationForm.value.agp == 'in'" value="in">Entrada</mat-option>
          <mat-option (selected)="reservationForm.value.agp == 'out'" value="out">Salida</mat-option>
        </mat-select>
      </mat-form-field> -->

        <mat-form-field class="price" *ngIf="field != '0'" appearance="outline">
          <mat-label>PRECIO</mat-label>
          <input formControlName="total" matInput type="number" step="0.01">
        </mat-form-field>

        <mat-form-field class="comision" *ngIf="field != '0'" appearance="outline">
          <mat-label>COMISION</mat-label>
          <input formControlName="commission" matInput type="number" step="0.01">
        </mat-form-field>

        <mat-form-field class="priceCobro" *ngIf="field != '0'" appearance="outline">
          <mat-label>COBRO</mat-label>
          <input formControlName="charge" matInput type="number" step="0.01">
        </mat-form-field>

        <div class="mat-form-field cobrar">
          <span>COBRAR</span>
          <mat-checkbox class="example-margin" formControlName="drivers_charges"></mat-checkbox>
        </div>

        <div class="mat-form-field fact">
          <span>FACTURAR</span>
          <mat-checkbox class="example-margin" formControlName="invoiced"></mat-checkbox>
        </div>

        <div class="mat-form-field private">
          <span>PRIVADO</span>
          <mat-checkbox class="example-margin" formControlName="private"></mat-checkbox>
        </div>

        <div class="mat-form-field sport">
          <span>EQUIPAMIENTO DEPORTIVO</span>
          <mat-checkbox class="example-margin" formControlName="sport_equipement"></mat-checkbox>
        </div>

        <mat-form-field class="comments" appearance="outline">
          <mat-label>COMENTARIOS</mat-label>
          <textarea formControlName="description" matInput type="text"> </textarea>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions>
      <button *ngIf="field == '0'" class="button" mat-stroked-button type="button" (click)="addReservation()">
        <mat-icon>save</mat-icon> Guardar Reserva
      </button>
      <button *ngIf="field != '0'" class="button" mat-stroked-button type="button" (click)="updateReservation()">
        <mat-icon>save</mat-icon>
        Actualizar Reserva
      </button>
    </mat-card-actions>
  </mat-card>

  <div *ngIf="field != '0'" class="box">
    <app-service [field]="field" [private]="reservationForm.controls['private'].value"></app-service>
  </div>

  <div *ngIf="field != '0'" class="box">
    <app-passenger [field]="field" [private]="reservationForm.controls['private'].value"></app-passenger>
  </div>

</div>