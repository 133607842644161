import { Injectable } from '@angular/core';
import { Touroperator } from '../interfaces/touroperator';
import { HttpClient } from '@angular/common/http';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { Agency } from '../interfaces/agency';
import { login } from '../interfaces/login';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class TouroperatorService extends BaseService {

  public limit = false;
  touroperators: Touroperator[] = [];
  private empty = new Subject<boolean>();
  
  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "touroperator") {
        this.touroperators = [];
        this.empty.next(true);
      }
    }});
  }

  getAllTouroperators():Observable<Touroperator[]>{
    const obs:Subject<Touroperator[]> = new Subject<Touroperator[]>();
    if (this.touroperators.length > 0) {
      setTimeout(() => {
        obs.next(...[this.touroperators]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getTouroperators()
      pet.subscribe((asd) => {
        this.touroperators = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get touroperatorsChanged() {
    return this.empty.asObservable();
  }

  getTouroperators():Observable<ApiListResponse<Touroperator>>{
    return this.http.get<ApiListResponse<Touroperator>>(this.apiUrl + "touroperator")
  }

  searchTouroperators(uuid:string):Observable<ApiResponse<Agency>>{
    return this.http.get<ApiResponse<Agency>>(this.apiUrl + "agency/" + uuid)
  }

  getTouroperatorById(id: string):Observable<ApiResponse<Touroperator>>{
    return this.http.get<ApiResponse<Touroperator>>(this.apiUrl + "touroperator/" + id)
  }

  addTouroperator(touroperator: Touroperator):Observable<ApiResponse<Touroperator>>{
    return this.http.post<ApiResponse<Touroperator>>(this.apiUrl + "agency/" + touroperator.agency_uuid, touroperator)
  }

  updateTouroperator(touroperator: Touroperator):Observable<ApiResponse<Touroperator>>{
    return this.http.put<ApiResponse<Touroperator>>(this.apiUrl + "touroperator/" + touroperator.uuid, touroperator)
  }

  deleteTouroperatorById(id: string):Observable<ApiResponse<Touroperator>>{
    return this.http.delete<ApiResponse<Touroperator>>(this.apiUrl + "touroperator/" + id)
  }
}
