import { Injectable } from '@angular/core';
import { Staff } from '../interfaces/staff';
import { HttpClient } from '@angular/common/http';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class StaffService extends BaseService {

  staffs: Staff[] = [];
  private empty = new Subject<boolean>();
  
  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "staff") {
        this.staffs = [];
        this.empty.next(true);
      }
    }});
  }

  getAllStaffs():Observable<Staff[]>{
    const obs:Subject<Staff[]> = new Subject<Staff[]>();
    if (this.staffs.length > 0) {
      setTimeout(() => {
        obs.next(...[this.staffs]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getStaffs()
      pet.subscribe((asd) => {
        this.staffs = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get staffsChanged() {
    return this.empty.asObservable();
  }

  getStaffs():Observable<ApiListResponse<Staff>>{
    return this.http.get<ApiListResponse<Staff>>(this.apiUrl + "staff")
  }

  getStaffById(id: string):Observable<ApiResponse<Staff>>{
    return this.http.get<ApiResponse<Staff>>(this.apiUrl + "staff/" + id)
  }

  addStaff(staff: Staff):Observable<ApiResponse<Staff>>{
    return this.http.post<ApiResponse<Staff>>(this.apiUrl + "staff", staff)
  }

  updateStaff(staff: Staff):Observable<ApiResponse<Staff>>{
    return this.http.put<ApiResponse<Staff>>(this.apiUrl + "staff/" + staff.uuid, staff)
  }

  deleteStaffById(id: string):Observable<ApiResponse<Staff>>{
    return this.http.delete<ApiResponse<Staff>>(this.apiUrl + "staff/" + id)
  }

  createUser(email: string, uuid: string):Observable<ApiResponse<Staff>>{
    return this.http.post<ApiResponse<Staff>>(this.apiUrl + "staff/" + uuid + "/createUser", {email:email})
  }

  resetPassword(uuid: string):Observable<ApiResponse<Staff>>{
    return this.http.post<ApiResponse<Staff>>(this.apiUrl + "staff/" + uuid + "/resetPassword","")
  }

  deactivate(uuid: string):Observable<ApiResponse<Staff>>{
    return this.http.post<ApiResponse<Staff>>(this.apiUrl + "staff/" + uuid + "/deactivate/", "")
  }

  activate(uuid: string):Observable<ApiResponse<Staff>>{
    return this.http.post<ApiResponse<Staff>>(this.apiUrl + "staff/" + uuid + "/activate/", "")
  }
}
