import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-mantenimiento-modal',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  templateUrl: './mantenimiento-modal.component.html',
  styleUrl: './mantenimiento-modal.component.css'
})
export class MantenimientoModalComponent {
  reload() {
    location.reload();
  }
}
