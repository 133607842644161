<div class="hv">
  <mat-form-field *ngIf="field == '0'" class="example-form-field mt-2" appearance="outline">
    <mat-label>Fecha</mat-label>
    <input matInput [(ngModel)]="date" [matDatepicker]="dpS" (dateChange)="getServices(date)">
    <mat-datepicker-toggle matIconSuffix [for]="dpS"></mat-datepicker-toggle>
    <mat-datepicker #dpS></mat-datepicker>
  </mat-form-field>

  <mat-card>
    <mat-card-header>
      <mat-card-title *ngIf="field != '0'">Servicios</mat-card-title>
    </mat-card-header>
    <mat-card-content  class="overflowScroll">
      <span *ngIf="serviceList.length == 0" >No hay servicios para mostrar</span>
      <table *ngIf="serviceList.length != 0" mat-table [dataSource]="dataSource" class="table" matSort (matSortChange)="sortData($event)">
        <!-- Position Column -->
        <ng-container matColumnDef="supplier">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="supplier"> PROVEEDOR </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="editTableElement(element, 'supplier')"> {{element.reservation?.supplier?.name}} </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> FECHA </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)"> {{element.date | date:'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="date_H">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="date_H"> HORA </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)"> {{element.date | date:'HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="dateF">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="end_date"> HORA FIN </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)"> {{element.end_date | date:'HH:mm'}} </td>
        </ng-container>

        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="location"> ORIGEN </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)">
            {{
              private || element.reservation?.private ? 
                element.origin_location_private :
                element.origin_location != null ? 
                  element.origin_location?.name :
                  element.reservation?.origin_zone?.name
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="destination">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="destination"> DESTINO FINAL </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)">
            {{
              private || element.reservation?.private ?
                element.destination_location_private :
                element.destination_location != null ? 
                  element.destination_location?.name :
                  element.reservation?.destination_zone?.name
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="driver">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="driver"> CONDUCTOR </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="editTableElement(element, 'staff')"> {{element.driver?.name}}
          </td>
        </ng-container>

        <ng-container matColumnDef="vehicle">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="vehicle"> VEHICULO </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="editTableElement(element, 'vehicle')">
            {{element.vehicle?.license_plate}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container *ngIf="field == '0'" matColumnDef="reference">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="reference"> NUMERO RESERVA </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)">
            {{element.reservation?.reference}} </td>
        </ng-container>

        <ng-container *ngIf="field == '0'" matColumnDef="customer_reference">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="customer_reference"> REFERENCIA CLIENTE </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)">
            {{element.reservation?.customer_reference}} </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="description"> SERVICIO </th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}" (click)="reservation(element.reservation_uuid)">
            {{element.description}}
          </td>
        </ng-container>

        <ng-container *ngIf="field == '0'" matColumnDef="duplicate">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let $i = index" [ngClass]="{'grey': !element.uuid}">
            <div *ngIf="!!element.uuid">
              <button mat-icon-button type="button" (click)="duplicateReservation(element.reservation_uuid)" matTooltip="Duplicar Reserva">
                <mat-icon fontSet="material-icons-round">content_copy</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="field == '0'" matColumnDef="hide" class="">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let $i = index" [ngClass]="{'grey': !element.uuid}">
            <div *ngIf="!!element.uuid">
              <button mat-icon-button type="button" (click)="showReservation($i)" matTooltip="{{element.show ? 'Ocultar Servicio' : 'Mostrar Servicio'}}">
                <mat-icon fontSet="material-icons-round">{{element.show ? 'visibility' : 'visibility_off'}}</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="field == '0'" matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element; let $i = index" [ngClass]="{'grey': !element.uuid}">
            <div *ngIf="!!element.uuid">
              <button mat-icon-button color="warn" type="button" (click)="removeService(element)" matTooltip="Borrar servicio">
                <mat-icon fontSet="material-icons-round">delete</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container *ngIf="field != '0'" matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" [ngClass]="{'grey': !element.uuid}">
            <button mat-icon-button type="button" (click)="sendService(element)">
              <mat-icon fontSet="material-icons-round">edit</mat-icon>
            </button>
            <button mat-icon-button color="warn" type="button" (click)="removeService(element)">
              <mat-icon fontSet="material-icons-round">backspace</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
    <mat-card-actions *ngIf="field != '0'">
      <button mat-stroked-button type="button" (click)="sendService()">
        <mat-icon fontSet="material-icons-round">add</mat-icon> Añadir un servicio
      </button>
    </mat-card-actions>
  </mat-card>

</div>
