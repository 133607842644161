<div class="hv">
  <div *ngIf="import">
    <button type="button" mat-raised-button (click)="fileInput.click()"> <mat-icon>publish</mat-icon>Seleccionar XML</button>
    <input hidden (change)="onFileSelected($event)" #fileInput type="file" accept=".xml" id="file">
  </div>
  <div *ngIf="!import">
      <mat-card *ngIf="touroperatorXml.length > 0">
        <mat-card-header>
          <mat-card-title>Mapeo de touroperadores</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div *ngFor="let touroperator of touroperatorXml">
            <span>{{touroperator.name}}</span>
            <app-touroperator-autocomplete #autocompleteTouroperatorComponent class="mat-form-field" (selected_touroperator)="assignTouroperator(touroperator.name, $event)"></app-touroperator-autocomplete>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <button mat-stroked-button
              (click)="addTtoo()">
              <mat-icon fontSet="material-icons-round">person_add</mat-icon> Crear touroperador
            </button>
        </mat-card-actions>
      </mat-card>

    <mat-card *ngIf="zoneXml.length > 0">
      <mat-card-header>
        <mat-card-title>Mapeo de zonas</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <div *ngFor="let zone of zoneXml">
        <span>{{zone.name}}</span>
        <app-zone-autocomplete #autocompleteZoneComponent class="mat-form-field" (selected_zone)="assignZone(zone.name, $event)"></app-zone-autocomplete>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button (click)="addZone()" >
          <mat-icon fontSet="material-icons-round">add_location_alt</mat-icon> Crear zona
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="locationXml.length > 0">
      <mat-card-header>
        <mat-card-title>Mapeo de localizaciones</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <div *ngFor="let location of locationXml">
        <span>{{location.name}}</span>
        <app-location-autocomplete #autocompleteLocationComponent class="mat-form-field" (selected_location)="assignLocation(location.name, $event)"></app-location-autocomplete>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button
          (click)="addLocation()"><mat-icon fontSet="material-icons-round">add_location</mat-icon> Crear localización
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="staffXml.length > 0">
      <mat-card-header>
        <mat-card-title>Mapeo de conductores</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <div *ngFor="let staff of staffXml">
        <span>{{staff.name}}</span>
        <app-staff-autocomplete #autocompleteStaffComponent class="mat-form-field" (selected_staff)="assignStaff(staff.name, $event)"></app-staff-autocomplete>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button (click)="addPersonal()">
          <mat-icon fontSet="material-icons-round">group_add</mat-icon> Crear personal
        </button>
      </mat-card-actions>
    </mat-card>
    <mat-card *ngIf="vehicleXml.length > 0">
      <mat-card-header>
        <mat-card-title>Mapeo de vehículos</mat-card-title>
      </mat-card-header>
      <mat-card-content>
      <div *ngFor="let vehicle of vehicleXml">
        <span>{{vehicle.name}}</span>
        <app-vehicle-autocomplete #autocompleteVehicleComponent class="mat-form-field" (selected_vehicle)="assignVehicle(vehicle.name, $event)"></app-vehicle-autocomplete>
      </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-stroked-button (click)="addVehicle()">
          <mat-icon fontSet="material-icons-round">group_add</mat-icon> Crear vehículo
        </button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
