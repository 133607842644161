import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../interfaces/ApiResponse';
import { login, loginResponse, logoutResponse, user } from '../interfaces/login';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends BaseService {

  public error: boolean = false;
  private user: user = {} as user;

  constructor(private http: HttpClient) {
    super();
  }

  login(login: login): Observable<ApiResponse<loginResponse>> {
    return this.http.post<ApiResponse<loginResponse>>(this.apiUrl + "auth/login", login)
  }

  logout() {
    return this.http.post<ApiResponse<logoutResponse>>(this.apiUrl + "auth/logout", "")
  }

  me() {
    return this.http.get<ApiResponse<user>>(this.apiUrl + "auth/me")
  }

  isAdmin(): boolean {
    return true;
  }

  setUser(user: user) {
    this.user = user;
  }

  getUser() {
    return this.user;
  }

  isLoged() {
    return (this.user && this.user.uuid);
  }

  inError(): boolean {
    return this.error;
  }

}
