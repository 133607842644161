<div class="back">
  <mat-accordion *ngIf="!loadingAgencies" class="example-headers-align" multi>
    <ng-container *ngFor="let agency of agencies; let $agencyIndex = index">
      <mat-expansion-panel (opened)="searchTouroperators($agencyIndex)">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{agency.name}}
          </mat-panel-title>
          <mat-panel-description>
            <button mat-icon-button (click)="edit($agencyIndex, $event)" matTooltip="Editar agencia"
              matTooltipPosition="left"><mat-icon fontSet="material-icons-round">edit</mat-icon></button>
            <button mat-icon-button (click)="deleteAgency($agencyIndex, $event)" matTooltip="Eliminar agencia"
              matTooltipPosition="left"><mat-icon fontSet="material-icons-round">delete_forever</mat-icon></button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list class="grid">
          <mat-list-item>
            <mat-icon matListItemIcon>badge</mat-icon>
            <div matListItemTitle>Cif</div>
            <div matListItemLine>{{agency.cif}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>email</mat-icon>
            <div matListItemTitle>Correo</div>
            <div matListItemLine>{{agency.email}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>phone</mat-icon>
            <div matListItemTitle>Teléfono</div>
            <div matListItemLine>{{agency.phone}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>location_city</mat-icon>
            <div matListItemTitle>Ciudad</div>
            <div matListItemLine>{{agency.city}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>apartment</mat-icon>
            <div matListItemTitle>Codigo Postal</div>
            <div matListItemLine>{{agency.postal_code}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>assistant_direction</mat-icon>
            <div matListItemTitle>Dirección</div>
            <div matListItemLine>{{agency.address}}</div>
          </mat-list-item>
        </mat-list>
        <mat-list>
          <div mat-subheader class="flexible">Touroperadores <span class="spacer"></span>
            <button mat-icon-button (click)="addTtoo($agencyIndex)" matTooltip="Añadir touroperador"
              matTooltipPosition="left" matTooltipHideDelay="100" title="Añadir touroperador">
              <mat-icon fontSet="material-icons-round">person_add</mat-icon>
            </button>
          </div>
          <div class="progressspin" *ngIf="isCharging(agency.uuid!)">
            <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
          </div>
          <div *ngIf="touroperators(agency) === 0 && !isCharging(agency.uuid!)" class="errorCentrado">
            No hay touroperadores para mostrar
          </div>
          <div *ngIf="!isCharging(agency.uuid!)">
            <mat-list>
              <mat-list-item *ngFor="let touroperator of agency.touroperators; let $touroperatorIndex = index">
                <div mat-line class="align-center">
                  {{touroperator.name}}
                  <span class="spacer"></span>
                  <button mat-icon-button color="accent" (click)="editTtoo($agencyIndex, $touroperatorIndex, $event)"
                    matTooltip="Editar touroperador" matTooltipPosition="above" matTooltipHideDelay="100"
                    title="Editar touroperador">
                    <mat-icon fontSet="material-icons-round">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="deleteTtoo($agencyIndex, $touroperatorIndex, $event)"
                    matTooltip="Eliminar touroperador" matTooltipPosition="above" matTooltipHideDelay="100"
                    title="Eliminar touroperador">
                    <mat-icon fontSet="material-icons-round">person_remove</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <mat-card *ngIf="agencies.length == 0 && !loadingAgencies">
    <mat-card-content>No hay agencias</mat-card-content>
  </mat-card>
   <!-- <img class="skeleton" src="assets/skeletons/acordeon.svg" alt="Loading agencies"> -->
  <!-- <mat-expansion-panel class="skeleton" disabled="true">
    <mat-expansion-panel-header>
      <mat-panel-title class="skeleton-text">

      </mat-panel-title>
      <mat-panel-description>
        <button mat-icon-button class="skeleton-button"> </button>
        <button mat-icon-button class="skeleton-button"> </button>
      </mat-panel-description>
    </mat-expansion-panel-header>
  </mat-expansion-panel> -->
  <button mat-fab class="corner" matTooltip="Crear agencia" matTooltipPosition="left"
    (click)="addAgency()">
    <mat-icon fontSet="material-icons-round">add_business</mat-icon>
  </button>
</div>
