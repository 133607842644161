<div class="hv">
  <mat-accordion class="example-headers-align" multi>
    <ng-container *ngFor="let vehicle of vehicles; let $vehicleIndex = index">
      <mat-expansion-panel hideToggle="true" disabled="true">
        <mat-expansion-panel-header>
          <mat-panel-title class="fit-content">
            {{vehicle.license_plate}}
          </mat-panel-title>
          <mat-panel-description>
            <button mat-icon-button (click)="edit($vehicleIndex, $event)">
              <mat-icon fontSet="material-icons-round">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteVehicle($vehicleIndex, $event)">
              <mat-icon fontSet="material-icons-round">delete_forever</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>

  <button mat-fab class="corner" (click)="addVehicle()" matTooltip="Crear vehículo"
    matTooltipPosition="left">
    <mat-icon fontSet="material-icons-round">add</mat-icon>
  </button>
</div>
