import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Direction } from '../interfaces/direction';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';
@Injectable({
  providedIn: 'root',

})

export class DirectionService extends BaseService {

  directions: Direction[] = [];
  private empty = new Subject<boolean>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "direction") {
        this.directions = [];
        this.empty.next(true);
      }
    }});
  }

  getAllDirections():Observable<Direction[]>{
    const obs:Subject<Direction[]> = new Subject<Direction[]>();
    if (this.directions.length > 0) {
      obs.next(this.directions);
      obs.complete();
    } else {
      let pet = this.getDirections()
      pet.subscribe((asd) => {
        this.directions = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get directionsChanged() {
    return this.empty.asObservable();
  }

  getDirections():Observable<ApiListResponse<Direction>>{
    return this.http.get<ApiListResponse<Direction>>(this.apiUrl + "direction")
  }

  getDirectionById(id: string):Observable<ApiResponse<Direction>>{
    return this.http.get<ApiResponse<Direction>>(this.apiUrl + "direction/" + id)
  }

  addDirection(direction: Direction):Observable<ApiResponse<Direction>>{
    return this.http.post<ApiResponse<Direction>>(this.apiUrl + "direction", direction)
  }

  updateDirection(direction: Direction):Observable<ApiResponse<Direction>>{
    return this.http.put<ApiResponse<Direction>>(this.apiUrl + "direction/" + direction.uuid, direction)
  }

  deleteDirectionById(id: string):Observable<ApiResponse<Direction>>{
    return this.http.delete<ApiResponse<Direction>>(this.apiUrl + "direction/" + id)
  }

}
