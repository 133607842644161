import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Supplier } from '../../../interfaces/supplier';
import { SupplierService } from '../../../services/supplier.service';
@Component({
  selector: 'app-supplier-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './supplier-autocomplete.component.html',
  styleUrl: './supplier-autocomplete.component.css'
})
export class SupplierAutocompleteComponent implements OnInit, OnDestroy {
  @Input() supplier_uuid: string = "";
  @Output() selected_supplier = new EventEmitter<string>();
  suppliers: Supplier[] = [];
  private suppliersChangedSubscription: Subscription = {} as Subscription;
  supplierFilteredOptions: Observable<Supplier[]> = new Observable<Supplier[]>();
  public supplierForm: FormGroup = this.fb.group({
    supplier_uuid: [""],
  })

  constructor(private supplierService: SupplierService, private fb: FormBuilder){}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['supplier_uuid']) {
      this.getSuppliers(true);
    }
  }

  ngOnInit(){
    this.supplierFilteredOptions = this.supplierForm.controls['supplier_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.suppliers;
      }),
    );
    this.getSuppliers(true);
    this.suppliersChangedSubscription = this.supplierService.suppliersChanged.subscribe({next: (net) => {
      if(net){
        this.getSuppliers();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.suppliersChangedSubscription) {
      this.suppliersChangedSubscription.unsubscribe();
    }
  }

  getSuppliers(search: boolean = false) {
    this.supplierService.getAllSuppliers().subscribe(data => {
      this.suppliers = data;
      if(search){
        this.supplierForm.patchValue({
          supplier_uuid: this.suppliers.filter(a => a.uuid == this.supplier_uuid)[0]
        });
      }
    })
  }

  display(supplier: Supplier): string {
    return supplier && supplier.name ? supplier.name : '';
  }

  private _filter(name: string): Supplier[] {
    const filterValue = name.toLowerCase();
    return this.suppliers.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string){
    this.selected_supplier.emit(uuid);
  }

  clear(){
    this.supplierForm.patchValue({
      supplier_uuid: ""
    });
    this.selected_supplier.emit();
  }
}
