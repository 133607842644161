import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Zone } from '../../../interfaces/zone';
import { ZoneService } from '../../../services/zone.service';

@Component({
  selector: 'app-zone-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './zone-autocomplete.component.html',
  styleUrl: './zone-autocomplete.component.css'
})
export class ZoneAutocompleteComponent implements OnInit, OnChanges, OnDestroy {
  @Input() zone_uuid: string = "";
  @Input() origin: boolean = false;
  @Input() destination: boolean = false;
  @Output() selected_zone = new EventEmitter<string>();
  zones: Zone[] = [];
  private zonesChangedSubscription: Subscription = {} as Subscription;
  zoneFilteredOptions: Observable<Zone[]> = new Observable<Zone[]>();
  public zoneForm: FormGroup = this.fb.group({
    zone_uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
  })

  constructor(private zoneService: ZoneService, private fb: FormBuilder) { }

  ngOnInit() {
    this.zoneFilteredOptions = this.zoneForm.controls['zone_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.zones;
      }),
    );
    this.getZones(true);
    this.zonesChangedSubscription = this.zoneService.zonesChanged.subscribe({next: (net) => {
      if(net){
        this.getZones();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.zonesChangedSubscription) {
      this.zonesChangedSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['zone_uuid']) {
      this.zoneForm.patchValue({
        zone_uuid: this.zones.filter(a => a.uuid == this.zone_uuid)[0]
      });
    }
  }


  getZones(search: boolean = false) {
    this.zoneService.getAllZones().subscribe(data => {
      this.zones = data;
      if(search){
        this.zoneForm.patchValue({
          zone_uuid: this.zones.filter(a => a.uuid == this.zone_uuid)[0]
        });
      }
    })
  }

  display(zone: Zone): string {
    return zone && zone.name ? zone.name : '';
  }

  private _filter(name: string): Zone[] {
    const filterValue = name.toLowerCase();
    return this.zones.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_zone.emit(uuid);
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.zoneForm.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  clear(){
    this.zoneForm.patchValue({
      zone_uuid: ""
    });
    this.selected_zone.emit();
  }
}
