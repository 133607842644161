import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-show-password',
  standalone: true,
  imports: [MatDialogModule, MatFormFieldModule, CommonModule, FormsModule, MatTooltipModule, MatInputModule],
  templateUrl: './show-password.component.html',
  styleUrl: './show-password.component.css'
})
export class ShowPasswordComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: {password: string}, private _sb: MatSnackBar) {}

  copy(passwd: string) {
    navigator.clipboard.writeText(passwd).then(a => {
      this._sb.open("Se ha copiado al portapapeles", undefined, {duration:1500});
    }).catch(e => {
      this._sb.open("No se ha podido copiar", undefined, {duration:3000});
    });
  }
}
