import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SecureStorageService } from '../services/secureStorage.service';

@Injectable({providedIn: 'root'})
export class CanActivateLogin implements CanActivate {
  constructor(private router: Router, private secureStorage: SecureStorageService) {
  }

  login: boolean | UrlTree = false;

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.secureStorage.get("token") != null && this.secureStorage.get("token") != "") {
      return true;
    } else {
      const tree: UrlTree = this.router.parseUrl('/login');
      return this.router.parseUrl('/login');
    }
  }

  cantActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.secureStorage.get("token") == null || this.secureStorage.get("token") == "") {
      return true;
    } else {
      const tree: UrlTree = this.router.parseUrl('/');
      return this.router.parseUrl('/');
    }
  }

}
