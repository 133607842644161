import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ZoneService } from '../../../services/zone.service';
import { StaffAutocompleteComponent } from '../../autocomplete/staff-autocomplete/staff-autocomplete.component';
import { SupplierAutocompleteComponent } from '../../autocomplete/supplier-autocomplete/supplier-autocomplete.component';
import { VehicleAutocompleteComponent } from '../../autocomplete/vehicle-autocomplete/vehicle-autocomplete.component';

export interface DiagReceived { supplier_uuid: string, driver_uuid: string, vehicle_uuid: string, type: string };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };

@Component({
  selector: 'app-table-form-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    SupplierAutocompleteComponent,
    StaffAutocompleteComponent,
    VehicleAutocompleteComponent,
    MatDialogModule
  ],
  templateUrl: './table-form-modal.component.html',
  styleUrl: './table-form-modal.component.css'
})
export class TableFormModalComponent implements OnInit{

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private zoneService: ZoneService) {
  }

  ngOnInit() {

  }

  assingSupplier(uuid: string){
    this.data.supplier_uuid = uuid;
  }

  assingDriver(uuid: string){
    this.data.driver_uuid = uuid;
  }

  assingVehicle(uuid: string){
    this.data.vehicle_uuid = uuid;
  }

  send(){
    this.dialogRef.close(this.data)
  }
}
