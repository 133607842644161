import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { Observable } from 'rxjs';
import { Location } from '../../../interfaces/location';
import { Zone } from '../../../interfaces/zone';
import { ZoneService } from '../../../services/zone.service';
import { ZoneAutocompleteComponent } from '../../autocomplete/zone-autocomplete/zone-autocomplete.component';

export interface DiagReceived { object: string, value: string };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };

@Component({
  selector: 'app-location-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    ZoneAutocompleteComponent,
    MatDialogModule
  ],
  templateUrl: './location-modal.component.html',
  styleUrl: './location-modal.component.css'
})
export class LocationModalComponent implements OnInit{

  zones: Zone[] = [];
  zoneFilteredOptions: Observable<Zone[]> = new Observable<Zone[]>();
  public locationForm: FormGroup = this.fb.group({
    zone_uuid:[],
    name: []
  })

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private zoneService: ZoneService) {
  }

  ngOnInit() {
  }

  assignZone(uuid: string){
    this.locationForm.patchValue({
      zone_uuid: uuid
    });
  }

  send(){
    const newTtoo : Location = {
      name: this.locationForm.value.name,
      zone_uuid: this.locationForm.value.zone_uuid
    }
    this.dialogRef.close(newTtoo)
  }
}
