<mat-dialog-content>
  <form [formGroup]="zoneForm" class="back">
    <mat-form-field appearance="outline" class="input">
      <mat-label>Zona</mat-label>
      <input matInput name="name" formControlName="name" autocomplete="off">
    </mat-form-field>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>Direccion</mat-label>
        <input type="text" matInput formControlName="direction" [matAutocomplete]="autoD">
        <mat-autocomplete #autoD="matAutocomplete" [displayWith]="displayD">
          @for (option of directionFilteredOptions | async; track option) {
          <mat-option [value]="option">{{option.name}}</mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
      <button mat-icon-button (click)="addDirection()" matTooltip="Añadir dirección" matTooltipPosition="left"
        matTooltipHideDelay="100" title="Añadir dirección">
        <mat-icon fontSet="material-icons-round">person_add</mat-icon>
      </button>
    </div>
    <mat-form-field appearance="outline" class="input">
      <mat-label>Orden</mat-label>
      <input matInput name="order" formControlName="order" autocomplete="off">
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="send()" class="ml-2">Añadir</button>
</mat-dialog-actions>
