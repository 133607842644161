import { Injectable } from '@angular/core';
import { Vehicle } from '../interfaces/vehicle';
import { HttpClient } from '@angular/common/http';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService extends BaseService {

  vehicles: Vehicle[] = [];
  private empty = new Subject<boolean>();
  
  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "vehicle") {
        this.vehicles = [];
        this.empty.next(true);
      }
    }});
  }

  getAllVehicles():Observable<Vehicle[]>{
    const obs:Subject<Vehicle[]> = new Subject<Vehicle[]>();
    if (this.vehicles.length > 0) {
      setTimeout(() => {
        obs.next(...[this.vehicles]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getVehicles()
      pet.subscribe((asd) => {
        this.vehicles = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get vehiclesChanged() {
    return this.empty.asObservable();
  }

  getVehicles():Observable<ApiListResponse<Vehicle>>{
    return this.http.get<ApiListResponse<Vehicle>>(this.apiUrl + "vehicle")
  }

  getVehicleById(id: string):Observable<ApiResponse<Vehicle>>{
    return this.http.get<ApiResponse<Vehicle>>(this.apiUrl + "vehicle/" + id)
  }

  addVehicle(vehicle: Vehicle):Observable<ApiResponse<Vehicle>>{
    return this.http.post<ApiResponse<Vehicle>>(this.apiUrl + "vehicle", vehicle)
  }

  updateVehicle(vehicle: Vehicle):Observable<ApiResponse<Vehicle>>{
    return this.http.put<ApiResponse<Vehicle>>(this.apiUrl + "vehicle/" + vehicle.uuid, vehicle)
  }

  deleteVehicleById(id: string):Observable<ApiResponse<Vehicle>>{
    return this.http.delete<ApiResponse<Vehicle>>(this.apiUrl + "vehicle/" + id)
  }
}
