<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #menuLateral class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false && sesionIniciada()">
    <mat-toolbar *ngIf="sesionIniciada()">Menú</mat-toolbar>
    <mat-nav-list *ngIf="sesionIniciada()">
      <a mat-list-item [routerLink]="['/reservation/0']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">event
          </mat-icon> <span class="spacel"></span>
          RESERVAS </span>
      </a>
      <a mat-list-item [routerLink]="['/service']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">departure_board</mat-icon> <span
            class="spacel"></span>
          SERVICIOS </span>
      </a>
      <a mat-list-item [routerLink]="['/import']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">move_to_inbox</mat-icon> <span
            class="spacel"></span>
          IMPORTAR </span>
      </a>
      <a mat-list-item [routerLink]="['/agency']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">explore</mat-icon> <span class="spacel"></span>
          AGENCIAS/CLIENTES </span>
      </a>
      <a mat-list-item [routerLink]="['/supplier']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">contacts</mat-icon> <span class="spacel"></span>
          PROVEEDORES </span>
      </a>
      <a mat-list-item [routerLink]="['/zone']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">location_on</mat-icon> <span
            class="spacel"></span> ZONAS </span>
      </a>
      <a mat-list-item [routerLink]="['/vehicles']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">directions_bus</mat-icon> <span
            class="spacel"></span> VEHICULOS </span>
      </a>
      <a mat-list-item [routerLink]="['/staff']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">engineering</mat-icon> <span
            class="spacel"></span>
          PERSONAL </span>
      </a>
      <a mat-list-item [routerLink]="['/invoice']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">note_add</mat-icon> <span
            class="spacel"></span>
          FACTURAR </span>
      </a>
      <a mat-list-item [routerLink]="['/invoiceList']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">snippet_folder</mat-icon> <span
            class="spacel"></span>
          LISTADO FACTURAS </span>
      </a>
      <a mat-list-item [routerLink]="['/statistics']" [routerLinkActive]="['active']" (click)="hideSideBar()">
        <span class="nList"> <mat-icon fontSet="material-icons-round">analytics</mat-icon> <span
            class="spacel"></span>
          ESTADISTICAS </span>
      </a>

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-container">
    <mat-toolbar color="primary" *ngIf="sesionIniciada()">
      <button *ngIf="sesionIniciada() && (isHandset$ | async)" (click)="menuLateral.toggle()" color="accent" mat-icon-button>
        <mat-icon fontSet="material-icons-round">menu</mat-icon>
      </button>
      <span [routerLink]="['/']" routerLinkActive="router-link-active">{{name}}</span>
      <span class="example-spacer"></span>
      <mat-progress-spinner class="separar" mode="indeterminate" *ngIf="(isLoading.inLoad$ | async)" [diameter]="(isHandset$ | async)?20:25" color="accent"></mat-progress-spinner>
      <button *ngIf="!sesionIniciada()" [routerLink]="['/login']" routerLinkActive="router-link-active"
        mat-stroked-button color="accent" aria-label="Botón iniciar sesión.">
        <mat-icon fontSet="material-icons-round">person</mat-icon>
        Iniciar sesión
      </button>

      <button *ngIf="sesionIniciada() && !(isHandset$ | async)" [matBadge]="isDev()" mat-stroked-button color="accent"
        aria-label="Menú de usuario." [matMenuTriggerFor]="menuUsuario" matTooltip="Menú de usuario"
        matTooltipPosition="below" matTooltipHideDelay="100" title="Menú de usuario">
        <mat-icon fontSet="material-icons-round">person</mat-icon>
        {{ user().name }}
      </button>
      <button *ngIf="sesionIniciada() && (isHandset$ | async)" [matBadge]="isDev()" mat-icon-button color="accent"
        aria-label="Menú de usuario." [matMenuTriggerFor]="menuUsuario">
        <mat-icon fontSet="material-icons-round">person</mat-icon>
      </button>

      <mat-menu #menuUsuario="matMenu">
        <menu *ngIf="sesionIniciada() && (isHandset$ | async)" mat-menu-item disabled>
          <mat-icon fontSet="material-icons-round">person</mat-icon>
          {{ user().name }}
        </menu>
        <mat-divider *ngIf="sesionIniciada() && (isHandset$ | async)"></mat-divider>
        <!-- <button *ngIf="sesionIniciada()" mat-menu-item aria-label="Panel de configuración de cuenta.">
          <mat-icon fontSet="material-icons-round">settings</mat-icon>
          Preferencias
        </button> -->
        <button *ngIf="sesionIniciada()" (click)="logout()" mat-menu-item aria-label="Botón cerrar sesión.">
          <mat-icon fontSet="material-icons-round">logout</mat-icon>
          Cerrar sesión
        </button>
      </mat-menu>

    </mat-toolbar>
    <router-outlet class="sidenav-container" *ngIf="!loadingDialogOpened && !loginService.inError()"></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
