import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { Agency } from '../../interfaces/agency';
import { Touroperator } from '../../interfaces/touroperator';
import { AgencyService } from '../../services/agency.service';
import { TitleService } from '../../services/title.service';
import { TouroperatorService } from '../../services/touroperator.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { NameModalComponent } from '../modal/name-modal/name-modal.component';
import { SupplierModalComponent } from '../modal/supplier-modal/supplier-modal.component';

@Component({
  selector: 'app-agency',
  standalone: true,
  providers: [provideNativeDateAdapter()],
  imports: [
    MatButtonModule,
    MatExpansionModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatTooltipModule,
    CommonModule,
    FormsModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatCardModule
  ],
  templateUrl: './agency.component.html',
  styleUrl: './agency.component.css',
})
export class AgencyComponent implements OnInit, OnDestroy {
  @ViewChild(MatAccordion)
  accordion: MatAccordion = new MatAccordion();
  private agenciesChangedSubscription: Subscription = {} as Subscription;
  public charging: string[] = [];
  public loadingAgencies: boolean = true;
  public agencies: Array<Agency> = new Array<Agency>();
  constructor(
    private agencyService: AgencyService,
    private touroperatorService: TouroperatorService,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private _title: TitleService
  ) {
    _title.setTitle('Agencias');
  }

  ngOnInit() {
    this.getAllAgencies();
    this.agenciesChangedSubscription =
      this.agencyService.agenciesChanged.subscribe({
        next: (net) => {
          if (net) {
            this.getAllAgencies();
          }
        },
      });
  }

  ngOnDestroy(): void {
    if (this.agenciesChangedSubscription) {
      this.agenciesChangedSubscription.unsubscribe();
    }
  }

  getAllAgencies() {
    this.agencyService.getAllAgencies().subscribe((data) => {
      this.agencies = data;
      this.loadingAgencies = false;
    });
  }

  touroperators(agency: Agency) {
    return agency.touroperators ? agency.touroperators.length : 0;
  }

  edit(index: number, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(SupplierModalComponent, {
      data: { object: 'Modificar Agencia', value: this.agencies[index].uuid },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != '' && res != undefined) {
        this.agencies[index] = res;
        this.agencyService
          .updateAgency(this.agencies[index])
          .subscribe((data) => {
            if (data.status == 'ok') {
              this.openSnackBar('Agencia modificada');
            }
          });
      }
    });
  }

  editTtoo(index: number, indexT: number, event: Event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: {
        object: 'Modificar Touroperador',
        value: this.agencies[index].touroperators![indexT].name,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res != '' && res != undefined) {
        this.agencies[index].touroperators![indexT].name = res;
        this.touroperatorService
          .updateTouroperator(this.agencies[index].touroperators![indexT])
          .subscribe((data) => {
            if (data.status == 'ok') {
              this.openSnackBar('Touroperador modificado');
            }
          });
      }
    });
  }

  searchTouroperators(index: number) {
    this.charging.push(this.agencies[index].uuid!);
    this.agencyService
      .getAgencyById(this.agencies[index].uuid!)
      .subscribe((data) => {
        if (data.status == 'ok') {
          this.agencies[index].touroperators = data.data.touroperators;
          this.charging.splice(
            this.charging.findIndex((r) => r == this.agencies[index].uuid!),
            1
          );
        }
      });
  }

  addAgency() {
    const dialogRef = this.dialog.open(SupplierModalComponent, {
      data: { object: 'Nueva Agencia' },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != '' && res != undefined) {
        const agency: Agency = {
          name: res.name,
          cif: res.cif,
          address: res.address,
          email: res.email,
          phone: res.phone,
          postal_code: res.postal_code,
          city: res.city,
          touroperators: new Array<Touroperator>(),
        };
        this.agencyService.addAgency(agency).subscribe((data) => {
          if (data.status == 'ok') {
            this.openSnackBar('Agencia creada');
            this.agencies.push(data.data);
          }
        });
      }
    });
  }

  addTtoo(index: number) {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: 'Nuevo Touroperador' },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res != '' && res != undefined) {
        const ttoo: Touroperator = {
          name: res,
          agency_uuid: this.agencies[index].uuid,
        };
        this.touroperatorService.addTouroperator(ttoo).subscribe((data) => {
          if (data.status == 'ok') {
            this.openSnackBar('Touroperador creado');
            this.agencies[index].touroperators?.push(data.data);
          }
        });
      }
    });
  }

  deleteTtoo(index: number, indexT: number, event: Event) {
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: {
        object:
          'el touroperador ' + this.agencies[index].touroperators![indexT].name,
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        this.touroperatorService
          .deleteTouroperatorById(
            this.agencies[index].touroperators![indexT].uuid!
          )
          .subscribe((data) => {
            if (data.status == 'ok') {
              this.openSnackBar('Touroperador eliminado');
              this.agencies[index].touroperators?.splice(indexT, 1);
            }
          });
      }
    });
  }

  deleteAgency(index: number, event: Event) {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: 'la agencia ' + this.agencies[index].name },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res == true) {
        this.agencyService
          .deleteAgencyById(this.agencies[index].uuid!)
          .subscribe((data) => {
            if (data.status == 'ok') {
              this.openSnackBar('Agencia eliminada');
              this.agencies.splice(index, 1);
            }
          });
      }
    });
  }

  isCharging(uuid: string) {
    return this.charging.find((r) => r == uuid) != undefined;
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: 'center',

      duration: 5000,
    });
  }
}
