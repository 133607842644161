import { Injectable } from '@angular/core';
import { Supplier } from '../interfaces/supplier';
import { HttpClient } from '@angular/common/http';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Observable, Subject } from 'rxjs';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService extends BaseService {

  suppliers: Supplier[] = [];
  private empty = new Subject<boolean>();
  
  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "supplier") {
        this.suppliers = [];
        this.empty.next(true);
      }
    }});
  }

  getAllSuppliers():Observable<Supplier[]>{
    const obs:Subject<Supplier[]> = new Subject<Supplier[]>();
    if (this.suppliers.length > 0) {
      setTimeout(() => {
        obs.next(...[this.suppliers]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getSuppliers()
      pet.subscribe((asd) => {
        this.suppliers = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get suppliersChanged() {
    return this.empty.asObservable();
  }

  getSuppliers():Observable<ApiListResponse<Supplier>>{
    return this.http.get<ApiListResponse<Supplier>>(this.apiUrl + "supplier")
  }

  getSupplierById(id: string):Observable<ApiResponse<Supplier>>{
    return this.http.get<ApiResponse<Supplier>>(this.apiUrl + "supplier/" + id)
  }

  addSupplier(supplier: Supplier):Observable<ApiResponse<Supplier>>{
    return this.http.post<ApiResponse<Supplier>>(this.apiUrl + "supplier", supplier)
  }

  updateSupplier(supplier: Supplier):Observable<ApiResponse<Supplier>>{
    return this.http.put<ApiResponse<Supplier>>(this.apiUrl + "supplier/" + supplier.uuid, supplier)
  }

  deleteSupplierById(id: string):Observable<ApiResponse<Supplier>>{
    return this.http.delete<ApiResponse<Supplier>>(this.apiUrl + "supplier/" + id)
  }
}
