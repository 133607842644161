<div class="hv">
  <mat-card class="mv">
      <mat-card-content class="overflowScroll">
        <form [formGroup]="reservationForm">
          <mat-form-field class="w-100" appearance="outline">
            <mat-label>Añadir Reserva</mat-label>
            <input type="text" matInput formControlName="reservation" [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="display">
              @for (option of supplierFilteredOptions | async; track option) {
              <mat-option [value]="option"
                (onSelectionChange)="changeInvoiceRow(option!)">{{option.reference + " " + option.service[0].description}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
          <button mat-stroked-button (click)="addInvoiceRow()">Añadir</button>
        </form>
      </mat-card-content>
  </mat-card>

  <mat-card class="mv" >
      <mat-card-header>
          <mat-card-title>Facturas</mat-card-title>
      </mat-card-header>
      <mat-card-content class="overflowScroll">
        <table *ngIf="invoiceRowList.length != 0" mat-table [dataSource]="dataSource" class="">
          <!-- Position Column -->
      
          <ng-container matColumnDef="reservation">
            <th mat-header-cell *matHeaderCellDef> Referencia </th>
            <td mat-cell *matCellDef="let element"> {{element.reservation.reference}} </td>
          </ng-container>
      
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> FECHA </th>
            <td mat-cell *matCellDef="let element"> {{element.reservation.date | date:'dd/MM/yyyy'}} </td>
          </ng-container>
      
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> SERVICIO </th>
            <td mat-cell *matCellDef="let element"> {{element.reservation.service[0].description}} </td>
          </ng-container>
      
          <ng-container matColumnDef="price">
            <th mat-header-cell *matHeaderCellDef> PRECIO </th>
            <td mat-cell *matCellDef="let element"> <span *ngIf="!element.edit">{{element.total}}</span></td>
          </ng-container>
      
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              <button mat-icon-button color="warn" type="button" (click)="removeInvoiceRow(element.uuid)">
                <mat-icon fontSet="material-icons-round">backspace</mat-icon>
              </button>
            </td>
          </ng-container>
      
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
  </mat-card>
  <mat-dialog-actions class="corner">
    <button mat-stroked-button (click)="back()">Volver</button>
    <button mat-stroked-button (click)="generateInvoice(true)">Facturar</button>
  </mat-dialog-actions>
</div>

