import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
  
})

export class BaseService{
    public apiUrl = environment.apiUrl;
    protected secretKey = environment.secretKey;
    protected wsConfig = environment.ws;
}