import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Observable, map, startWith } from 'rxjs';
import { Invoice } from '../../interfaces/invoice';
import { Invoice_Row } from '../../interfaces/invoicerow';
import { ReservationInvoice } from '../../interfaces/reservation';
import { InvoiceService } from '../../services/invoice.service';
import { LocationAutocompleteComponent } from '../autocomplete/location-autocomplete/location-autocomplete.component';

@Component({
  selector: 'app-invoice-form',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatDatepickerModule,
    LocationAutocompleteComponent,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTableModule,
  ],
  templateUrl: './invoice-form.component.html',
  styleUrl: './invoice-form.component.css'
})
export class InvoiceFormComponent implements OnInit {

  public invoice: Invoice = {} as Invoice;
  reservationList: ReservationInvoice[] = [];
  invoiceRowList: Invoice_Row[] = [];
  field: string = "";

  displayedColumns: string[] = ['reservation', 'date', 'description', 'price', 'action'];
  dataSource = new MatTableDataSource(this.invoiceRowList);

  supplierFilteredOptions: Observable<ReservationInvoice[]> = new Observable<ReservationInvoice[]>();

  public reservationForm: FormGroup = this.fb.group({
    invoice_row_uuid: [""],
    reservation: [""]
  })

  constructor(private fb: FormBuilder, private activatedRoute: ActivatedRoute, private invoiceService: InvoiceService, private _snackBar: MatSnackBar, private router: Router) {
  }

  ngOnInit(){
    this.activatedRoute.paramMap.subscribe(params => {
      this.field = params.get("id")!;
    });
    this.supplierFilteredOptions = this.reservationForm.controls['reservation'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.reference + " " + value?.service[0].description;
        return name ? this._filter(name as string) : this.reservationList;
      }),
    );
    this.getInvoice();
  }

  getInvoice(){
    this.invoiceService.getInvoice(this.field).subscribe(data => {
      if(data.status == "ok"){
        this.invoice = data.data;
        this.invoiceRowList = this.invoice.invoice_row;
        this.dataSource = new MatTableDataSource(this.invoiceRowList);
        this.getReservations();
      }
    })
  }


  display(reservation: ReservationInvoice): string {
    return reservation && reservation.reference ? reservation.reference + " " + reservation.service[0].description : '';
  }

  private _filter(reference: string): ReservationInvoice[] {
    const filterValue = reference;
    return this.reservationList.filter(a => a.reference?.toString().includes(filterValue));
  }

  getReservations(){
    this.invoiceService.getReservations(this.invoice.supplier_uuid, this.invoice.agency_uuid, undefined, undefined, undefined, undefined, undefined, undefined).subscribe(data => {
      if(data.status == "ok"){
        this.reservationList = data.data;
      }
    })
  }

  changeInvoiceRow(res: ReservationInvoice){
    this.reservationForm.patchValue({
      invoice_row_uuid : res.invoice_row_uuid
    })
  }

  addInvoiceRow() {
    let uuid = this.reservationForm.value.invoice_row_uuid;
    if(!!uuid){
      this.invoiceService.addInvoiceRow(this.invoice.uuid, uuid).subscribe(data => {
        if(data.status == "ok"){
          this.invoice = data.data;
          this.invoiceRowList = this.invoice.invoice_row;
          this.dataSource = new MatTableDataSource(this.invoiceRowList);
          this.getReservations();
          this.reservationForm.patchValue({
            invoice_row_uuid : "",
            reservation : "",
          })
        }
      })
    }else{
      this.openSnackBar("Selecciona una reserva");
    }

  }

  removeInvoiceRow(uuid: string) {
    this.invoiceService.deleteInvoiceRow(this.invoice.uuid, uuid).subscribe(data => {
      if(data.status == "ok"){
        this.getInvoice();
      }
    })
  }

  generateInvoice(flag: boolean = false){
    let invoices = this.invoiceRowList.map(r => r.uuid);
    this.invoiceService.generateInvoice(this.field).subscribe(data => {
      if(data.status == "ok"){
        this.router.navigate(["invoiceList"]);
      }
    })
  }

  back(){
    this.router.navigate(["invoiceList"]);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }

}
