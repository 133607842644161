import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Invoice } from '../interfaces/invoice';
import { ReservationInvoice } from '../interfaces/reservation';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService extends BaseService {

  constructor(private http: HttpClient) {
    super();
  }

  getInvoice(uuid: string):Observable<ApiResponse<Invoice>>{
    return this.http.get<ApiResponse<Invoice>>(this.apiUrl + "invoice/" + uuid)
  }

  getReservations(supplier_uuid?: string, agency_uuid?: string, touroperator_uuid?: string, staff_uuid?: string, vehicle_uuid?: string, startDate?: Date, endDate?: Date, invoiced?: boolean):Observable<ApiListResponse<ReservationInvoice>>{
    let supplier = !!supplier_uuid ? "supplier_uuid=" + supplier_uuid + "&": "";
    let agency = !!agency_uuid ? "agency_uuid=" + agency_uuid + "&": "";
    let touroperator = !!touroperator_uuid ? "touroperator_uuid=" + touroperator_uuid + "&": "";
    let staff = !!staff_uuid ? "staff_uuid=" + staff_uuid + "&": "";
    let vehicle = !!vehicle_uuid ? "vehicle_uuid=" + vehicle_uuid + "&": "";
    let start = !!startDate ? "startDate=" + startDate!.toLocaleDateString('es-es', {day:"2-digit",month:"2-digit",year:"numeric"}) + "&": "";
    let end = !!endDate ? "endDate=" + endDate!.toLocaleDateString('es-es', {day:"2-digit",month:"2-digit",year:"numeric"}) : "";
    let resinvoiced = invoiced == null ? "" : invoiced == false ? "&invoiced=" + 0 : "&invoiced=" + 1;
    return this.http.get<ApiListResponse<ReservationInvoice>>(this.apiUrl + "reservation?" + supplier + agency + touroperator + staff + vehicle + start + end + resinvoiced)
  }

  getInvoices(supplier_uuid: string, touroperator_uuid: string, invoiced: boolean, startDate?: Date, endDate?: Date):Observable<ApiListResponse<Invoice>>{
    let start = !!startDate ? "&startDate=" + startDate!.toLocaleDateString('es-es', {day:"2-digit",month:"2-digit",year:"numeric"}) : null;
    let end = !!endDate ? "&endDate=" + endDate!.toLocaleDateString('es-es', {day:"2-digit",month:"2-digit",year:"numeric"}) : null;
    let flag = invoiced ? 1 : 0;
    return this.http.get<ApiListResponse<Invoice>>(this.apiUrl + "invoice?supplier_uuid=" + supplier_uuid + "&touroperator_uuid=" + touroperator_uuid + start + end + "&invoiced=" + flag)
  }

  addInvoice(uuids: string[]){
    return this.http.post(this.apiUrl + "invoice", {invoiceRows: uuids})
  }

  addInvoiceRow(invoice: string, row: string):Observable<ApiResponse<Invoice>>{
    return this.http.put<ApiResponse<Invoice>>(this.apiUrl + "invoice/" + invoice + "/invrow", {invoiceRow: row})
  }

  deleteInvoiceRow(invoice: string, row: string):Observable<ApiResponse<Invoice>>{
    return this.http.delete<ApiResponse<Invoice>>(this.apiUrl + "invoice/" + invoice + "/invrow", {body: {invoiceRow: row}})
  }

  generateInvoice(uuid: string):Observable<ApiResponse<Invoice>>{
    return this.http.post<ApiResponse<Invoice>>(this.apiUrl + "invoice/" + uuid + "/invoice", {})
  }

  download(uuid: string): Observable<any>{
    return this.http.get<any>(this.apiUrl + "invoice/" + uuid + "/generate", { responseType: 'blob' as 'json' });
  }

  send(uuid: string, destinations: number): Observable<any>{
    return this.http.post<any>(this.apiUrl + "invoice/" + uuid + "/mail", {sendTo: destinations});
  }


}
