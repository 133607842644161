import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SecureStorageService } from './secureStorage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthStateService {
  
  isAdmin(): boolean {
    return true
  }
  private userState = new BehaviorSubject<boolean>(true);

  constructor(public token: SecureStorageService) {
    this.setAuthState(!!this.token.get('token'));
   }

  userAuthState = this.userState.asObservable();

  userAuthStateF() {
    return this.userState;
  }
  
  setAuthState(value: boolean) {
    this.userState.next(value);
  }

  logout() {
    throw new Error('Method not implemented.');
  }



}
