import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../interfaces/ApiResponse';
import { Mapping } from '../interfaces/mapping';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class MappingService extends BaseService {


  constructor(private http: HttpClient) {
    super();
  }

  addMapping(mapping: Mapping):Observable<ApiResponse<Mapping>>{
    return this.http.post<ApiResponse<Mapping>>(this.apiUrl + "mapping", mapping)
  }


}

