<div class="hv">
  <mat-accordion *ngIf="suppliers.length > 0 && !loadingSuppliers" class="example-headers-align" multi>
    <ng-container *ngFor="let supplier of suppliers; let $supplierIndex = index">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title class="fit-content">
            {{supplier.name}}
          </mat-panel-title>
          <mat-panel-description>
            <button mat-icon-button (click)="edit($supplierIndex, $event)" matTooltip="Editar proveedor">
              <mat-icon fontSet="material-icons-round">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteSupplier($supplierIndex, $event)" matTooltip="Borrar proveedor">
              <mat-icon fontSet="material-icons-round">delete_forever</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list>
          <mat-list-item>
            <mat-icon matListItemIcon>badge</mat-icon>
            <div matListItemTitle>Cif</div>
            <div matListItemLine>{{supplier.cif}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>email</mat-icon>
            <div matListItemTitle>Correo</div>
            <div matListItemLine>{{supplier.email}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>phone</mat-icon>
            <div matListItemTitle>Teléfono</div>
            <div matListItemLine>{{supplier.phone}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>location_city</mat-icon>
            <div matListItemTitle>Ciudad</div>
            <div matListItemLine>{{supplier.city}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>apartment</mat-icon>
            <div matListItemTitle>Codigo Postal</div>
            <div matListItemLine>{{supplier.postal_code}}</div>
          </mat-list-item>
          <mat-list-item>
            <mat-icon matListItemIcon>assistant_direction</mat-icon>
            <div matListItemTitle>Dirección</div>
            <div matListItemLine>{{supplier.address}}</div>
          </mat-list-item>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <mat-card *ngIf="suppliers.length == 0 && !loadingSuppliers">
    <mat-card-content>No hay proveedores</mat-card-content>
  </mat-card>
  <button mat-fab class="corner" (click)="addSupplier()" matTooltip="Crear proveedor"
    matTooltipPosition="left">
    <mat-icon fontSet="material-icons-round">group_add</mat-icon>
  </button>
</div>
