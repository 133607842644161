import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

export interface DiagReceived { object: string, value: string };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };
@Component({
  selector: 'app-accept-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatDialogModule
  ],
  templateUrl: './accept-modal.component.html',
  styleUrl: './accept-modal.component.css'
})
export class AcceptModalComponent {
  public field: string = "";

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived,) {
    this.field = data.object
  }

  send(response: boolean) {
    this.dialogRef.close(response)
  }
}
