import { Injectable } from '@angular/core';
import { Passenger } from '../interfaces/reservation';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PassengerService extends BaseService {
  
  
  constructor(private http: HttpClient) {
    super();
  }

  getAllPassengerByReservation(uuid: string):Observable<ApiListResponse<Passenger>>{
    return this.http.get<ApiListResponse<Passenger>>(this.apiUrl + "passenger/reservation/" + uuid)
  }

  addPassenger(passenger: Passenger):Observable<ApiResponse<Passenger>>{
    return this.http.post<ApiResponse<Passenger>>(this.apiUrl + "passenger/reservation/"+passenger.reservation_uuid, passenger)
  }

  updatePassenger(passenger: Passenger):Observable<ApiResponse<Passenger>>{
    return this.http.put<ApiResponse<Passenger>>(this.apiUrl + "passenger/" + passenger.uuid, passenger)
  }

  deletePassengerById(id: string):Observable<ApiResponse<Passenger>>{
    return this.http.delete<ApiResponse<Passenger>>(this.apiUrl + "passenger/" + id)
  }

}
