import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Mapping } from '../interfaces/mapping';
import { Reservation } from '../interfaces/reservation';
import { Service } from '../interfaces/service';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class ServiceService extends BaseService {

  services: Service[] = [];
  private empty = new Subject<boolean>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "service") {
        this.services = [];
        this.empty.next(true);
      }
    }});
  }

  getServices(date?:Date):Observable<Service[]>{
    const obs:Subject<Service[]> = new Subject<Service[]>();
    let dateS = !!date ? "?date=" + date.toLocaleDateString('es-es', {day:"2-digit",month:"2-digit",year:"numeric"}) : "";
    if (this.services.length > 0 && localStorage.getItem("serviceDate") == dateS) {
      setTimeout(() => {
        obs.next(...[this.services]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getAllServices(dateS)
      pet.subscribe((asd) => {
        this.services = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get servicesChanged() {
    return this.empty.asObservable();
  }

  getAllServices(date: string):Observable<ApiListResponse<Service>>{
    localStorage.setItem("serviceDate", date);
    return this.http.get<ApiListResponse<Service>>(this.apiUrl + "service" + date)
  }


  getServiceById(id: string):Observable<ApiResponse<Service>>{
    return this.http.get<ApiResponse<Service>>(this.apiUrl + "service/" + id)
  }

  getServiceByReservationId(id: string):Observable<ApiListResponse<Service>>{
    return this.http.get<ApiListResponse<Service>>(this.apiUrl + "service/reservation/" + id)
  }

  addService(service: Service):Observable<ApiResponse<Service>>{
    return this.http.post<ApiResponse<Service>>(this.apiUrl + "service", service)
  }

  updateService(service: Service):Observable<ApiResponse<Service>>{
    return this.http.put<ApiResponse<Service>>(this.apiUrl + "service/" + service.uuid, service)
  }

  deleteServiceById(id: string):Observable<ApiResponse<Service>>{
    return this.http.delete<ApiResponse<Service>>(this.apiUrl + "service/" + id)
  }

  importServices(json: object):Observable<ApiListResponse<Mapping>>
  {
    return this.http.post<ApiListResponse<Mapping>>(this.apiUrl + "reservation/import", json)
  }

  duplicateReservation(uuid: string, date: Date):Observable<ApiResponse<Reservation>>{
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
    let dateString = `${year}-${month}-${day}`;
    return this.http.get<ApiResponse<Reservation>>(this.apiUrl + "reservation/" + uuid + "/duplicate?date=" + dateString)
  }
}
