<div class="hv">
  <mat-accordion *ngIf="zones.length > 0 && !loadingZones" class="example-headers-align" multi>
    <ng-container *ngFor="let zone of zones; let $zoneIndex = index">
      <mat-expansion-panel (opened)="getZoneById($zoneIndex)">
        <mat-expansion-panel-header>
          <mat-panel-title class="fit-content">
            {{zone.name}}
          </mat-panel-title>
          <mat-panel-description>
            <button mat-icon-button (click)="showPrice($zoneIndex, $event)">
              <mat-icon fontSet="material-icons-round">euro</mat-icon>
            </button>
            <button mat-icon-button (click)="edit($zoneIndex, $event)">
              <mat-icon fontSet="material-icons-round">edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteZone($zoneIndex, $event)">
              <mat-icon fontSet="material-icons-round">delete_forever</mat-icon>
            </button>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-list>
          <div mat-subheader class="flexible">Localizaciones <span class="spacer"></span> <button mat-icon-button
              (click)="addLocation($zoneIndex)" matTooltip="Añadir localizacion" matTooltipPosition="left"
              matTooltipHideDelay="100" title="Añadir localizacion">
              <mat-icon fontSet="material-icons-round">add_location</mat-icon>
            </button>
          </div>
          <div class="progressspin" *ngIf="cargandoZonas">
            <mat-progress-spinner diameter="50" mode="indeterminate"></mat-progress-spinner>
          </div>
          <div *ngIf="!cargandoZonas && locations(zone) === 0" class="errorCentrado">
            No hay localizaciones para mostrar
          </div>
          <div *ngIf="!cargandoZonas">
            <mat-list>
              <mat-list-item *ngFor="let location of zone.location; let $locationIndex = index">
                <div mat-line class="align-center">
                  {{location.name}}
                  <span class="spacer"></span>
                  <button mat-icon-button color="accent" (click)="editLocation($zoneIndex, $locationIndex)"
                    matTooltip="Editar localizacion" matTooltipPosition="above" matTooltipHideDelay="100"
                    title="Editar localizacion">
                    <mat-icon fontSet="material-icons-round">edit</mat-icon>
                  </button>
                  <button mat-icon-button color="warn" (click)="deleteLocation($zoneIndex, $locationIndex)"
                    matTooltip="Eliminar localizacion" matTooltipPosition="above" matTooltipHideDelay="100"
                    title="Eliminar localizacion">
                    <mat-icon fontSet="material-icons-round">remove</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>
          </div>
        </mat-list>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
  <mat-card *ngIf="zones.length == 0 && !loadingZones">
    <mat-card-content>No hay zonas</mat-card-content>
  </mat-card>
  <button mat-fab class="corner" (click)="addZone()" matTooltip="Crear zona" matTooltipPosition="left">
    <mat-icon fontSet="material-icons-round">add_location_alt</mat-icon>
  </button>
</div>
