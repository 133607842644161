import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Location } from '../interfaces/location';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class LocationService extends BaseService {

  locations: Location[] = [];
  private empty = new Subject<boolean>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "location") {
        this.locations = [];
        this.empty.next(true);
      }
    }});
  }

  getAllLocations():Observable<Location[]>{
    const obs:Subject<Location[]> = new Subject<Location[]>();
    if (this.locations.length > 0) {
      setTimeout(() => {
        obs.next(...[this.locations]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getLocations()
      pet.subscribe((asd) => {
        this.locations = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get locationsChanged() {
    return this.empty.asObservable();
  }

  getLocations():Observable<ApiListResponse<Location>>{
    return this.http.get<ApiListResponse<Location>>(this.apiUrl + "location")
  }

  getLocationById(id: string):Observable<ApiResponse<Location>>{
    return this.http.get<ApiResponse<Location>>(this.apiUrl + "location/" + id)
  }

  addLocation(location: Location):Observable<ApiResponse<Location>>{
    return this.http.post<ApiResponse<Location>>(this.apiUrl + "location", location)
  }

  updateLocation(location: Location):Observable<ApiResponse<Location>>{
    return this.http.put<ApiResponse<Location>>(this.apiUrl + "location/" + location.uuid, location)
  }

  deleteLocationById(id: string):Observable<ApiResponse<Location>>{
    return this.http.delete<ApiResponse<Location>>(this.apiUrl + "location/" + id)
  }
}
