import { Routes } from '@angular/router';
import { AgencyComponent } from './components/agency/agency.component';
import { ImportComponent } from './components/import/import.component';
import { InvoiceFormComponent } from './components/invoice-form/invoice-form.component';
import { InvoiceListComponent } from './components/invoice-list/invoice-list.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { LoginComponent } from './components/login/login.component';
import { ReservationComponent } from './components/reservation/reservation.component';
import { ServiceComponent } from './components/service/service.component';
import { StaffComponent } from './components/staff/staff.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { SupplierComponent } from './components/supplier/supplier.component';
import { VehicleComponent } from './components/vehicle/vehicle.component';
import { ZoneComponent } from './components/zone/zone.component';
import { CanActivateLogin } from './interfaces/canactivatelogin';
import { CantActivateLogin } from './interfaces/cantactivatelogin';

export const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [CantActivateLogin] },
  { path: '', redirectTo: "service", pathMatch: "full" },
  { path: 'service', component: ServiceComponent, canActivate: [CanActivateLogin], data: { title: "Servicios" } },
  { path: 'reservation/:id', component: ReservationComponent, canActivate: [CanActivateLogin], data: { title: "Reservas" } },
  { path: 'reservation/:id/:next', component: ReservationComponent, canActivate: [CanActivateLogin], data: { title: "Reservas" } },
  { path: 'agency', component: AgencyComponent, canActivate: [CanActivateLogin], data: { title: "Agencias" } },
  { path: 'vehicles', component: VehicleComponent, canActivate: [CanActivateLogin], data: { title: "Vehículos" } },
  { path: 'supplier', component: SupplierComponent, canActivate: [CanActivateLogin], data: { title: "Proveedores" } },
  { path: 'import', component: ImportComponent, canActivate: [CanActivateLogin], data: { title: "Importación" } },
  { path: 'zone', component: ZoneComponent, canActivate: [CanActivateLogin], data: { title: "Zonas" } },
  { path: 'staff', component: StaffComponent, canActivate: [CanActivateLogin], data: { title: "Personal" } },
  { path: 'invoice', component: InvoiceComponent, canActivate: [CanActivateLogin], data: { title: "Facturar" } },
  { path: 'invoice/:id', component: InvoiceFormComponent, canActivate: [CanActivateLogin], data: { title: "Facturar" } },
  { path: 'invoiceList', component: InvoiceListComponent, canActivate: [CanActivateLogin], data: { title: "Listado Facturas" } },
  { path: 'statistics', component: StatisticsComponent, canActivate: [CanActivateLogin], data: { title: "Estadisticas" } }
];
