import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject, Renderer2 } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Supplier } from '../../../interfaces/supplier';
import { AgencyService } from '../../../services/agency.service';
import { SupplierService } from '../../../services/supplier.service';

export interface DiagReceived { object: string, value: string, error?: any, supplier?: Supplier };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };
@Component({
  selector: 'app-supplier-modal',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatDialogModule,
    MatIconModule,
    MatTooltipModule
  ],
  templateUrl: './supplier-modal.component.html',
  styleUrl: './supplier-modal.component.css'
})
export class SupplierModalComponent {
  public error: any = {};
  public field: string = "";
  public value: string = "";
  public supplierForm: FormGroup = this.fb.group({
    uuid: [],
    name: [, [Validators.required, Validators.minLength(2), Validators.maxLength(150)]],
    email: [, [Validators.required, Validators.minLength(3), Validators.maxLength(250), Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
    phone: [, [Validators.required, Validators.minLength(6), Validators.maxLength(12)]],
    address: [, [Validators.required, Validators.minLength(1), Validators.maxLength(250)]],
    cif: [, [Validators.required, Validators.minLength(5), Validators.maxLength(12)]],
    postal_code: new FormControl('', {validators: [ Validators.required, Validators.minLength(5), Validators.maxLength(5)], updateOn: 'blur' }),
    city: [, [Validators.required, Validators.minLength(1), Validators.maxLength(50)]]
  })
  errors: string[] = [];
  constructor(private supplierService: SupplierService, private agencyService: AgencyService, private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
  @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private _snackBar: MatSnackBar, private el: ElementRef, private renderer: Renderer2) {
    this.field = data.object;
    this.value = data.value;
    this.error = data.error;
    if(this.field.includes("Proveedor")){
      this.setDataSupplier();
    }else if(this.field.includes("Agencia")){
      this.setDataAgency();
    }

  }

  setDataSupplier() {
    if (!!this.value) {
      this.supplierService.getSupplierById(this.value).subscribe(data => {
        if (data.status == "ok") {
          this.supplierForm.setValue({
            uuid: data.data.uuid,
            name: data.data.name,
            email: data.data.email,
            phone: data.data.phone,
            address: data.data.address,
            cif: data.data.cif,
            postal_code: data.data.postal_code,
            city: data.data.city
          })
        }
      })
    } else if (!! this.data.supplier) {
      this.supplierForm.setValue({
        uuid: this.data.supplier.uuid || '',
        name: this.data.supplier.name || '',
        email: this.data.supplier.email || '',
        phone: this.data.supplier.phone || '',
        address: this.data.supplier.address || '',
        cif: this.data.supplier.cif || '',
        postal_code: this.data.supplier.postal_code || '',
        city: this.data.supplier.city || ''
      })
    }
  }

  setDataAgency() {
    if (!!this.value) {
      this.agencyService.getAgencyById(this.value).subscribe(data => {
        if (data.status == "ok") {
          this.supplierForm.setValue({
            uuid: data.data.uuid,
            name: data.data.name,
            email: data.data.email,
            phone: data.data.phone,
            address: data.data.address,
            cif: data.data.cif,
            postal_code: data.data.postal_code,
            city: data.data.city
          })
        }
      })
    }
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.supplierForm.get(inputName);
  
    if (input && input.touched) {
      if (input.hasError('required')) {
        return 'Debe introducir un valor.';
      }

      if (input.hasError('pattern')) {
        return 'Debe introducir números';
      }

      if (input.hasError('minlength') || input.hasError('maxlength')) {
        return inputShow;
      }

      return input.invalid ? inputShow + '.' : '';
    }
    return '';
  }

  send() {
    if(this.supplierForm.valid){
      const supplier: Supplier = this.supplierForm.value;
      this.dialogRef.close(supplier);
    }else{
      this.openSnackBar("Rellene los campos");
    }
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }
}
