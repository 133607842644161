import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Price } from '../../interfaces/price';
import { PriceService } from '../../services/price.service';

export interface DiagReceived { object: string, value: number };
export interface Errors { nombre?: string[], nombre_corto?: string[], codBarras?: string[], familia_id?: string[], color?: string[], estado?: string[] };
@Component({
  selector: 'app-rate',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule
  ],
  templateUrl: './rate.component.html',
  styleUrl: './rate.component.css'
})
export class RateComponent {
  public field: string = "";
  public order: number = 0;
  public price: Price[] = [];
  public priceFormArray: FormGroup = this.fb.group({
    prices: this.fb.array([]),
  });
  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>,
    @Inject(MAT_DIALOG_DATA) public data: DiagReceived, private priceService: PriceService) {
    this.field = data.object;
    this.order = data.value;
    this.getPrices()
  }

  getPrices() {
    this.priceService.getAllPriceByZone(this.field).subscribe(data => {
      if (data.status == "ok") {
        if(data.data.length > 0){
          for (const price of data.data) {
            this.addPrice(price);
          }
        }else{
          this.addPrice();
        }

      }
    })
  }

  addPrice(price?: Price) {
    const priceGroup = this.fb.group({
      uuid: [""],
      pax_min: [0],
      pax_max: [0],
      price: [0],
      zone_uuid: [this.field],
    });

    if (price != null) {
      priceGroup.controls.pax_max.setValue(price?.pax_max!);
      priceGroup.controls.uuid.setValue(price?.uuid!);
      priceGroup.controls.pax_min.setValue(price?.pax_min!);
      priceGroup.controls.price.setValue(price?.price!);
      priceGroup.controls.zone_uuid.setValue(price?.zone_uuid!);

    }

    this.prices.push(priceGroup);
  }

  removePrice(index: number) {
    if(this.prices.value[index].uuid != ""){
      this.priceService.deletePriceById(this.prices.value[index].uuid).subscribe(data => {
        if (data.status == "ok") {
          this.prices.removeAt(index);
        }
      })
    }else{
      this.prices.removeAt(index);
    }

  }

  sendPrice(index: number) {
    if (this.prices.value[index].uuid == "") {
      this.priceService.addPrice(this.prices.value[index]).subscribe(data => {
        if (data.status == "ok") {
        }
      })
    } else {
      this.priceService.updatePrice(this.prices.value[index]).subscribe(data => {
        if (data.status == "ok") {
        }
      })
    }

  }

  get prices() {
    return (this.priceFormArray.get('prices') as FormArray);
  }

  send(response: boolean) {
    this.dialogRef.close(response)
  }
}
