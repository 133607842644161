import { Component, OnInit } from '@angular/core';
import { ServiceService } from '../../services/service.service';
import { MatFormField, MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule } from '@angular/forms';
import { Location } from '../../interfaces/location';
import { LocationService } from '../../services/location.service';
import { Mapping } from '../../interfaces/mapping';
import { MappingService } from '../../services/mapping.service';
import { ZoneService } from '../../services/zone.service';
import { Zone } from '../../interfaces/zone';
import { ReservationService } from '../../services/reservation.service';
import { Touroperator } from '../../interfaces/touroperator';
import { TouroperatorService } from '../../services/touroperator.service';
import { StaffService } from '../../services/staff.service';
import { Staff } from '../../interfaces/staff';
import { VehicleService } from '../../services/vehicle.service';
import { Vehicle } from '../../interfaces/vehicle';
import { Router } from '@angular/router';
import { PassengerService } from '../../services/passenger.service';
import { MatIconModule } from '@angular/material/icon';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TtooModalComponent } from '../modal/ttoo-modal/ttoo-modal.component';
import { ZoneModalComponent } from '../modal/zone-modal/zone-modal.component';
import { NameModalComponent } from '../modal/name-modal/name-modal.component';
import { LocationModalComponent } from '../modal/location-modal/location-modal.component';
import { LocationAutocompleteComponent } from '../autocomplete/location-autocomplete/location-autocomplete.component';
import { ZoneAutocompleteComponent } from '../autocomplete/zone-autocomplete/zone-autocomplete.component';
import { TouroperatorAutocompleteComponent } from '../autocomplete/touroperator-autocomplete/touroperator-autocomplete.component';
import { StaffAutocompleteComponent } from '../autocomplete/staff-autocomplete/staff-autocomplete.component';
import { VehicleAutocompleteComponent } from '../autocomplete/vehicle-autocomplete/vehicle-autocomplete.component';
import { TitleService } from '../../services/title.service';
import { MatCardModule } from '@angular/material/card';

@Component({
  selector: 'app-import',
  standalone: true,
  imports: [
    MatInputModule,
    MatFormField,
    MatButtonModule,
    CommonModule,
    MatAutocompleteModule,
    FormsModule,
    MatIconModule,
    LocationAutocompleteComponent,
    ZoneAutocompleteComponent,
    TouroperatorAutocompleteComponent,
    StaffAutocompleteComponent,
    VehicleAutocompleteComponent,
    MatCardModule
  ],
  templateUrl: './import.component.html',
  styleUrl: './import.component.css'
})
export class ImportComponent implements OnInit {
  mapping: Mapping[] = [];
  touroperatorXml: Mapping[] = [];
  zoneXml: Mapping[] = [];
  locationXml: Mapping[] = [];
  vehicleXml: Mapping[] = [];
  staffXml: Mapping[] = [];
  touroperatorField: Touroperator = {} as Touroperator;
  import: boolean = true;
  json: any = {};

  constructor(private servicesService: ServiceService, private locationService: LocationService, private mappingService: MappingService,
    private zoneService: ZoneService, private reservationService: ReservationService, private touroperatorService: TouroperatorService,
    private staffService: StaffService, private vehicleService: VehicleService, private passengerService: PassengerService, private router: Router, public dialog: MatDialog,
    private _snackBar: MatSnackBar, private _title: TitleService) {
      _title.setTitle("Importar")

  }

  ngOnInit() {

  }

  assignTouroperator(touroperator: string, newUuid: string) {
    const newMapping: Mapping = {
      internal_id: newUuid,
      internal_type: "Touroperator",
      name: touroperator
    };
    const index = this.touroperatorXml.findIndex(z => z.name == touroperator && z.internal_type == "Touroperator");
    this.mappingService.addMapping(newMapping).subscribe(data => {
      if (data.status == "ok") {
        this.touroperatorXml.splice(index, 1);
        this.checkAll();
      }
    })
  }

  assignZone(zone: string, newUuid: string,) {
    const newMapping: Mapping = {
      internal_id: newUuid,
      internal_type: "Zone",
      name: zone
    };
    const index = this.zoneXml.findIndex(z => z.name == zone && z.internal_type == "Zone");
    this.mappingService.addMapping(newMapping).subscribe(data => {
      if (data.status == "ok") {
        this.zoneXml.splice(index, 1);
        this.checkAll();
      }
    })
  }

  assignStaff(driver: string, newUuid: string) {
    const newMapping: Mapping = {
      internal_id: newUuid,
      internal_type: "Staff",
      name: driver
    };
    const index = this.staffXml.findIndex(z => z.name == driver && z.internal_type == "Staff");
    this.mappingService.addMapping(newMapping).subscribe(data => {
      if (data.status == "ok") {
        this.staffXml.splice(index, 1);
        this.checkAll();
      }
    })
  }

  assignLocation(location: string, newUuid: string) {
    const newMapping: Mapping = {
      internal_id: newUuid,
      internal_type: "Location",
      name: location
    };
    const index = this.locationXml.findIndex(z => z.name == location && z.internal_type == "Location");
    this.mappingService.addMapping(newMapping).subscribe(data => {
      if (data.status == "ok") {
        this.locationXml.splice(index, 1);
        this.checkAll();
      }
    })
  }


  assignVehicle(vehicle: string, newUuid: string) {
    const newMapping: Mapping = {
      internal_id: newUuid,
      internal_type: "Vehicle",
      name: vehicle
    };
    const index = this.vehicleXml.findIndex(z => z.name == vehicle && z.internal_type == "Vehicle");
    this.mappingService.addMapping(newMapping).subscribe(data => {
      if (data.status == "ok") {
        this.vehicleXml.splice(index, 1);
        this.checkAll();
      }
    })
  }

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const xmlContent: string = fileReader.result as string;
        this.sendServices({ "xml": xmlContent });
        //this.handleXmlContent(xmlContent);
      };
      fileReader.readAsText(file);
    }
  }

  sendServices(json: object) {
    this.json = json;
    this.servicesService.importServices(json).subscribe(data => {
      this.import = false;
      if (data.status == "ok") {
        this.router.navigate(['/service'])
        this.openSnackBar("Se ha importado correctamente el XML");
      } else {
        this.openSnackBar("Debe mapear algunos apartados antes de importar el XML.");
        this.touroperatorXml = data.data.filter(m => m.internal_type == "Touroperator");
        this.zoneXml = data.data.filter(m => m.internal_type == "Zone");
        this.locationXml = data.data.filter(m => m.internal_type == "Location");
        this.vehicleXml = data.data.filter(m => m.internal_type == "Vehicle");
        this.staffXml = data.data.filter(m => m.internal_type == "Staff");
      }
    })
  }

  checkAll(){
    if(this.touroperatorXml.length == 0 && this.zoneXml.length == 0 && this.locationXml.length == 0 && this.staffXml.length == 0 && this.vehicleXml.length == 0){
      this.sendServices(this.json);
    }
  }

  addTtoo() {
    const dialogRef = this.dialog.open(TtooModalComponent, {
      data: { object: "Nuevo Touroperador" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const ttoo: Touroperator = res
        this.touroperatorService.addTouroperator(ttoo).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Touroperador creado");
            this.touroperatorField = data.data;
          }
        })
      }

    })
  }

  addZone() {
    const dialogRef = this.dialog.open(ZoneModalComponent, {
      data: {},
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const zone: Zone = res;
        this.zoneService.addZone(zone).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Zona creada");
          }
        })
      }
    })
  }

  addLocation() {
    const dialogRef = this.dialog.open(LocationModalComponent, {
      data: { object: "Nueva Localizacion" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const location: Location = res
        this.locationService.addLocation(location).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Localizacion creada")
          }
        })
      }

    })
  }

  addPersonal() {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nuevo Personal" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const staff: Staff = {
          name: res
        };
        this.staffService.addStaff(staff).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Personal creado");
          }
        })
      }

    })
  }

  addVehicle() {
    const dialogRef = this.dialog.open(NameModalComponent, {
      data: { object: "Nuevo vehiculo" },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const vehicle: Vehicle = {
          license_plate: res
        }
        this.vehicleService.addVehicle(vehicle).subscribe(data => {
          if (data.status == "ok") {
            this.openSnackBar("Vehiculo creado");
          }
        })
      }

    })
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }
}
