import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'app-sin-servidor-modal',
  standalone: true,
  imports: [
    MatIconModule,
    MatButtonModule,
    MatDialogModule
  ],
  templateUrl: './sin-servidor-modal.component.html',
  styleUrl: './sin-servidor-modal.component.css'
})
export class SinServidorModalComponent {
  reload() {
    location.reload();
  }
}
