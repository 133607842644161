import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Vehicle } from '../../../interfaces/vehicle';
import { VehicleService } from '../../../services/vehicle.service';

@Component({
  selector: 'app-vehicle-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './vehicle-autocomplete.component.html',
  styleUrl: './vehicle-autocomplete.component.css'
})
export class VehicleAutocompleteComponent implements OnInit, OnDestroy {
  @Input() vehicle_uuid: string = "";
  @Output() selected_vehicle = new EventEmitter<string>();
  vehicles: Vehicle[] = [];
  private vehiclesChangedSubscription: Subscription = {} as Subscription;
  vehicleFilteredOptions: Observable<Vehicle[]> = new Observable<Vehicle[]>();
  public vehicleForm: FormGroup = this.fb.group({
    vehicle_uuid: [""],
  })

  constructor(private vehicleService: VehicleService, private fb: FormBuilder) { }

  ngOnInit() {
    this.vehicleFilteredOptions = this.vehicleForm.controls['vehicle_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.vehicles;
      }),
    );
    this.getVehicles(true);
    this.vehiclesChangedSubscription = this.vehicleService.vehiclesChanged.subscribe({next: (net) => {
      if(net){
        this.getVehicles();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.vehiclesChangedSubscription) {
      this.vehiclesChangedSubscription.unsubscribe();
    }
  }

  getVehicles(search: boolean = false) {
    this.vehicleService.getAllVehicles().subscribe(data => {
      this.vehicles = data;
      if(search){
        this.vehicleForm.patchValue({
          vehicle_uuid: this.vehicles.filter(a => a.uuid == this.vehicle_uuid)[0]
        });
      }
    })
  }

  display(vehicle: Vehicle): string {
    return vehicle && vehicle.license_plate ? vehicle.license_plate : '';
  }

  private _filter(name: string): Vehicle[] {
    const filterValue = name.toLowerCase();
    return this.vehicles.filter(a => a.license_plate?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_vehicle.emit(uuid);
  }

  clear(){
    this.vehicleForm.patchValue({
      vehicle_uuid: ""
    });
    this.selected_vehicle.emit();
  }

}
