import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ApiListResponse, ApiResponse } from '../interfaces/ApiResponse';
import { Zone } from '../interfaces/zone';
import { BaseService } from './base.service';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneService extends BaseService {

  zones: Zone[] = [];
  private empty = new Subject<boolean>();

  constructor(private http: HttpClient, private websocketService: WebsocketService) {
    super();
    websocketService.dataObs.subscribe({next: (type) => {
      if (type === "zone") {
        this.zones = [];
        this.empty.next(true);
      }
    }});
  }

  getAllZones():Observable<Zone[]>{
    const obs:Subject<Zone[]> = new Subject<Zone[]>();
    if (this.zones.length > 0) {
      setTimeout(() => {
        obs.next(...[this.zones]);
        obs.complete();
      }, 1)
    } else {
      let pet = this.getZones()
      pet.subscribe((asd) => {
        this.zones = asd.data;
        this.empty.next(false);
        obs.next(asd.data);
        obs.complete();
      });
    }
    return obs.asObservable();
  }

  get zonesChanged() {
    return this.empty.asObservable();
  }

  getZones():Observable<ApiListResponse<Zone>>{
    return this.http.get<ApiListResponse<Zone>>(this.apiUrl + "zone")
  }

  getZoneById(id: string):Observable<ApiResponse<Zone>>{
    return this.http.get<ApiResponse<Zone>>(this.apiUrl + "zone/" + id)
  }

  addZone(zone: Zone):Observable<ApiResponse<Zone>>{
    return this.http.post<ApiResponse<Zone>>(this.apiUrl + "zone", zone)
  }

  updateZone(zone: Zone):Observable<ApiResponse<Zone>>{
    return this.http.put<ApiResponse<Zone>>(this.apiUrl + "zone/" + zone.uuid, zone)
  }

  deleteZoneById(id: string):Observable<ApiResponse<Zone>>{
    return this.http.delete<ApiResponse<Zone>>(this.apiUrl + "zone/" + id)
  }
}
