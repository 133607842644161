import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCard, MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { Service } from '../../interfaces/service';
import { LocationAutocompleteComponent } from '../autocomplete/location-autocomplete/location-autocomplete.component';
import { StaffAutocompleteComponent } from '../autocomplete/staff-autocomplete/staff-autocomplete.component';
import { VehicleAutocompleteComponent } from '../autocomplete/vehicle-autocomplete/vehicle-autocomplete.component';
export interface DiagReceived { object: boolean, value: Service };
@Component({
  selector: 'app-serviceForm-form',
  standalone: true,
  imports: [
    MatInputModule,
    CommonModule,
    FormsModule,
    MatButtonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatCard,
    MatCardModule,
    MatIconModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    MatProgressSpinnerModule,
    VehicleAutocompleteComponent,
    StaffAutocompleteComponent,
    LocationAutocompleteComponent,
    MatDialogModule
  ],
  templateUrl: './services-form.component.html',
  styleUrl: './services-form.component.css'
})
export class ServiceFormComponent implements OnInit {
  public order: number = 0;
  public service: Service[] = [];
  public private: boolean = false;
  public serviceForm: FormGroup = this.fb.group({
    uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    origin_location_uuid: [""],
    destination_location_uuid: [""],
    origin_location_private: [""],
    destination_location_private: [""],
    date: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    time: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    description: [""],
    end_date: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    endTime: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    driver_uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    vehicle_uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
    reservation_uuid: [],
  });

  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<DiagReceived>, @Inject(MAT_DIALOG_DATA) public data: DiagReceived,  private _snackBar: MatSnackBar) {

  }

  ngOnInit(){
    this.private = this.data.object;
    this.getService();
  }

  getService() {
    if(!!this.data.value.uuid){
      this.data.value.date = new Date(this.data.value.date);
      this.data.value.end_date = new Date(this.data.value.end_date);
      this.serviceForm.patchValue({
        uuid: this.data.value.uuid,
        date: this.data.value.date,
        time: this.data.value.date.getHours() + ":" + this.data.value.date.getMinutes(),
        origin_location_uuid: this.data.value.origin_location_uuid,
        destination_location_uuid: this.data.value.destination_location_uuid,
        origin_location_private: this.data.value.origin_location_private,
        destination_location_private: this.data.value.destination_location_private,
        description: this.data.value.description,
        end_date: this.data.value.end_date,
        endTime: this.data.value.end_date.getHours() + ":" + this.data.value.end_date.getMinutes(),
        driver_uuid: this.data.value.driver_uuid,
        reservation_uuid: this.data.value.reservation_uuid,
        vehicle_uuid: this.data.value.vehicle_uuid,
      });
    }else{
      this.serviceForm.patchValue({
        reservation_uuid: this.data.value.reservation_uuid
      });
    }
  }

  assignOrigin(uuid: string){
    this.serviceForm.patchValue({
      origin_location_uuid: uuid
    });
  }

  assignDestination(uuid: string){
    this.serviceForm.patchValue({
      destination_location_uuid: uuid
    });
  }

  assignDriver(uuid: string){
    this.serviceForm.patchValue({
      driver_uuid: uuid
    });
  }

  assignVehicles(uuid: string){
    this.serviceForm.patchValue({
      vehicle_uuid: uuid
    });
  }

  sendService(){
    if(this.valid()){
      let newDate : Date = new Date(this.serviceForm.value.date);
      newDate.setHours(parseInt(this.serviceForm.value.time.split(":")[0]), parseInt(this.serviceForm.value.time.split(":")[1]));
      this.serviceForm.value.date = newDate;
      newDate = new Date(this.serviceForm.value.end_date);
      newDate.setHours(parseInt(this.serviceForm.value.endTime.split(":")[0]), parseInt(this.serviceForm.value.endTime.split(":")[1]));
      this.serviceForm.value.end_date = newDate;
      const service: Service = this.serviceForm.value;
      this.dialogRef.close(service);
    }else{
      this.openSnackBar("Rellene todos los campos necesarios");
    }

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'x', {
      horizontalPosition: "center",
    });
  }

  valid(){
    if((!this.serviceForm.value.origin_location_uuid && !this.serviceForm.value.origin_location_private) || (!this.serviceForm.value.destination_location_uuid && !this.serviceForm.value.destination_location_private) || !this.serviceForm.value.date || !this.serviceForm.value.end_date || !this.serviceForm.value.time || !this.serviceForm.value.endTime){
      return false;
    }
    return true;
  }
}
