import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { login } from '../../interfaces/login';
import { AuthStateService } from '../../services/authState.service';
import { LoginService } from '../../services/login.service';
import { SecureStorageService } from '../../services/secureStorage.service';
import { TitleService } from '../../services/title.service';
import { WebsocketService } from '../../services/websocket.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    CommonModule,
    FormsModule,
    MatCardModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatProgressBarModule,
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.css'
})
export class LoginComponent implements OnInit {

  public loading: boolean = false;
  public ver:string = environment.ver;

  public formLogin: FormGroup = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  })
  constructor(private loginService: LoginService, private secureStorageService: SecureStorageService, private authService: AuthStateService, private _snackBar: MatSnackBar, private router: Router, private fb: FormBuilder, private _title: TitleService,  private websocketService: WebsocketService,) {
    _title.setTitle("Iniciar sesión")
  }
  ngOnInit() {
  }

  login() {
    this.formLogin.get('email')?.disable()
    this.formLogin.get('password')?.disable()
    this.loading = true;
    const user: login = {
      username: this.formLogin.value.email,
      email: this.formLogin.value.email,
      password: this.formLogin.value.password
    }
    this.loginService.login(user).subscribe({
      next: (data) => {
        this.secureStorageService.set("token", data.data.token);
        this.authService.setAuthState(true);
        this.loginService.me().subscribe({
          next: (respo) => {
            this.loginService.setUser(respo.data);
            this.openSnackBar(`Bienvenido de nuevo ${respo.data.name}`);
            this.websocketService.initial();
            this.router.navigate(['']);
            this.loading = false;
            this.formLogin.get('email')?.enable()
            this.formLogin.get('password')?.enable()
          },
          error: (err) => {
          }
        });
      }, error: (error) => {
        if (error.status === 403 || error.status === 422) {

          this.openSnackBar("Usuario o contraseña inválidos");
          this.formLogin.get('password')?.setValue("");
          this.loading = false;
          this.formLogin.get('email')?.enable()
          this.formLogin.get('password')?.enable()
        }
      }
    })
  }

  mailErrorMessage(inputName: string, inputShow: string) {
    let input = this.formLogin.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      horizontalPosition: "center",
      duration: 5000
    });
  }
}
