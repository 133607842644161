import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Observable, Subscription, map, startWith } from 'rxjs';
import { Location } from '../../../interfaces/location';
import { LocationService } from '../../../services/location.service';
@Component({
  selector: 'app-location-autocomplete',
  standalone: true,
  imports: [
    MatInputModule,
    MatAutocompleteModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule
  ],
  templateUrl: './location-autocomplete.component.html',
  styleUrl: './location-autocomplete.component.css'
})
export class LocationAutocompleteComponent implements OnInit, OnDestroy {
  @Input() location_uuid: string = "";
  @Input() origin: boolean = false;
  @Input() destination: boolean = false;
  @Output() selected_location = new EventEmitter<string>();
  locations: Location[] = [];
  locationFilteredOptions: Observable<Location[]> = new Observable<Location[]>();
  private locationsChangedSubscription: Subscription = {} as Subscription;
  public locationForm: FormGroup = this.fb.group({
    location_uuid: ["", [Validators.required, Validators.minLength(1), Validators.maxLength(25)]],
  })

  constructor(private locationService: LocationService, private fb: FormBuilder) { }

  ngOnInit() {
    this.locationFilteredOptions = this.locationForm.controls['location_uuid'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const name = typeof value === 'string' ? value : value?.name;
        return name ? this._filter(name as string) : this.locations;
      }),
    );
    this.getLocations(true);
    this.locationsChangedSubscription = this.locationService.locationsChanged.subscribe({next: (net) => {
      if(net){
        this.getLocations();
      }
    }});
  }

  ngOnDestroy(): void {
    if (this.locationsChangedSubscription) {
      this.locationsChangedSubscription.unsubscribe();
    }
  }

  getLocations(search: boolean = false) {
    this.locationService.getAllLocations().subscribe(data => {
      this.locations = data;
      if(search){
        this.locationForm.patchValue({
          location_uuid: this.locations.filter(a => a.uuid == this.location_uuid)[0]
        });
      }
    })
  }

  display(location: Location): string {
    return location && location.name ? location.name : '';
  }

  private _filter(name: string): Location[] {
    const filterValue = name.toLowerCase();
    return this.locations.filter(a => a.name?.toLowerCase().includes(filterValue));
  }

  send(uuid: string) {
    this.selected_location.emit(uuid);
  }

  errorMessage(inputName: string, inputShow: string) {
    let input = this.locationForm.get(inputName) || null;

    if (input && input?.touched) {

      if (input.hasError('required') || false) {
        return 'Debe introducir un valor.';
      }
      return input.invalid ? inputShow + '.' : '';
    }
    return "";

  }

  clear(){
    this.locationForm.patchValue({
      location_uuid: ""
    });
    this.selected_location.emit();
  }
}
