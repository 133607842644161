import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsloadingService {

  private susMuertos: string[] = [];

  private isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  public addLoad(whatLoad: string) {
    this.susMuertos.push(whatLoad);
    this.isLoading.next(true);
  }

  public delLoad(whatLoad: string) {
    this.susMuertos.splice(this.susMuertos.indexOf(whatLoad), 1);
    if (this.susMuertos.length == 0) {
      this.isLoading.next(false);
    }
  }

  public inLoad$: Observable<boolean> = this.isLoading.asObservable();

}
