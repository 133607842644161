<mat-dialog-content>
  <div class="back">
    <mat-form-field appearance="outline" class="input">
      <mat-label>{{field}}</mat-label>
      <input matInput name="name" [(ngModel)]="name" autocomplete="off">
    </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="send()" class="ml-2">Añadir</button>
</mat-dialog-actions>
