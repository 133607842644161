import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Service } from '../../interfaces/service';
import { ReservationService } from '../../services/reservation.service';
import { ServiceService } from '../../services/service.service';
import { TitleService } from '../../services/title.service';
import { AcceptModalComponent } from '../modal/accept-modal/accept-modal.component';
import { DateModalComponent } from '../modal/date-modal/date-modal.component';
import { TableFormModalComponent } from '../modal/table-form-modal/table-form-modal.component';
import { ServiceFormComponent } from '../services-form/services-form.component';

@Component({
  selector: 'app-service',
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatPaginatorModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatButtonModule,
    MatCardModule,
    MatSortModule
  ],
  templateUrl: './service.component.html',
  styleUrl: './service.component.css'
})
export class ServiceComponent implements OnInit, OnChanges {
  @Input() field: string = "0";
  @Input() private: boolean = false;
  myDriverControl = new FormControl();
  myVehicleControl = new FormControl();
  date: Date = new Date;
  date1: Date = new Date;
  date2: Date = new Date;
  displayedColumns: string[] = ['supplier', 'driver', 'vehicle', 'date', 'date_H', 'location', 'description', 'destination', 'dateF', 'reference', 'customer_reference', 'duplicate', 'hide', 'delete'];
  serviceList: Service[] = [];
  day1List: Service[] = [];
  day2List: Service[] = [];
  day3List: Service[] = [];
  originalServiceList: Service[] = [];
  dataSource = new MatTableDataSource(this.serviceList);
  sort: Sort = {} as Sort;
  private servicesChangedSubscription: Subscription = {} as Subscription;

  constructor(private serviceService: ServiceService, private reservationService: ReservationService, public dialog: MatDialog, private router: Router, private _title: TitleService) {
  }

  ngOnInit() {
    this._title.setTitle("Servicios");
    let date = new Date();
    this.date1.setDate(date.getDate() + 1);
    this.date2.setDate(date.getDate() + 2);
    if (this.field != "0") {
      this.displayedColumns = ['driver', 'vehicle', 'date', 'date_H', 'location', 'description', 'destination', 'dateF', 'actions'];
      this.getServicesByReservation();
    } else {
      this.getServices();
      this.servicesChangedSubscription = this.serviceService.servicesChanged.subscribe({next: (net) => {
        if(net){
          this.getServices();
        }
      }});
    }

  }

  ngOnDestroy(): void {
    if (this.field == "0") {
      this.servicesChangedSubscription.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['private'] && !changes['private'].firstChange) {
      this.getServicesByReservation();
    }
  }


  getServices(date?: Date) {
    if (this.field != '0') {
      this.getServicesByReservation();
    } else {
      this.serviceService.getServices(date).subscribe(data => {
        this.originalServiceList = [...data];
        this.serviceList = [];
        this.day1List = [];
        this.day2List = [];
        this.day3List = [];
        this.splitServices();
        this.joinArrays();
        
        this.dataSource = new MatTableDataSource(this.serviceList);
        this.sortData(this.sort);
      })
    }
  }

  splitServices(){
    let day1 = this.originalServiceList.indexOf(this.originalServiceList.filter(s => new Date(s.date).setHours(0, 0, 0, 0) == this.date1.setHours(0, 0, 0, 0))[0]);
    let day2 = this.originalServiceList.indexOf(this.originalServiceList.filter(s => new Date(s.date).setHours(0, 0, 0, 0) == this.date2.setHours(0, 0, 0, 0))[0]);

    this.day1List = [...this.originalServiceList.slice(0, (day1 > -1 ? day1 : (day2 > -1 ? day2 : this.originalServiceList.length)))];

    if(day1 > -1){
      this.day2List = [...this.originalServiceList.slice(day1, day2 > -1 ? day2 : this.originalServiceList.length)];
    }

    if(day2 > -1){
      this.day3List = [...this.originalServiceList.slice(day2)];
    }
    
  }

  joinArrays() {
    this.day1List.forEach(element => {
      this.serviceList.push(element);
    });

    if(this.day2List.length > 0){
      this.serviceList.push({} as Service);

      this.day2List.forEach(element => {
        this.serviceList.push(element);
      });
    }
    
    if(this.day3List.length > 0){
      this.serviceList.push({} as Service);
    
      this.day3List.forEach(element => {
        this.serviceList.push(element);
      });
    }
  }

  getServicesByReservation() {
    this.serviceService.getServiceByReservationId(this.field).subscribe(data => {
      if (data.status == "ok") {
        this.serviceList = data.data;
        this.originalServiceList = data.data;
        this.dataSource = new MatTableDataSource(this.serviceList);
      }
    })
  }

  sendService(service?: Service) {
    if (service == null) {
      service = {} as Service;
      service.reservation_uuid = this.field;
    }

    const dialogRef = this.dialog.open(ServiceFormComponent, {
      data: { object: this.private, value: { ...service } },

    })

    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        service = res;

        if (!service?.driver_uuid) {
          delete service?.driver_uuid
        }

        if (!service?.vehicle_uuid) {
          delete service?.vehicle_uuid
        }

        if (!service?.uuid) {
          this.serviceService.addService(service!).subscribe(data => {
            if (data.status == "ok") {
              this.getServicesByReservation()
            } else {
              this.sendService(service)
            }
          })
        } else {
          this.serviceService.updateService(service!).subscribe(data => {
            if (data.status == "ok") {
              this.getServicesByReservation()
            } else {
              this.sendService(service)
            }
          })
        }
      }
    });
  }

  duplicateReservation(uuid: string){
    const dialogRef = this.dialog.open(DateModalComponent, {
      data: { },

    })

    dialogRef.afterClosed().subscribe(res => {
      if (res != "" && res != undefined) {
        const date = res;
        this.serviceService.duplicateReservation(uuid, date).subscribe(data => {
          if(data.status == "ok"){
            this.router.navigate(["reservation/" + data.data.uuid])
          }
        })
      }
    });

  }

  removeService(service: Service) {
    const dialogRef = this.dialog.open(AcceptModalComponent, {
      data: { object: "el servicio " + service.reservation.reference },
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res == true) {
        this.serviceService.deleteServiceById(service.uuid!).subscribe(data => {
          if (this.field != "0") {
            this.getServicesByReservation();
          } else {
            this.getServices();
          }
          if(this.sort.active){
            this.sortData(this.sort);
          }
        })
      }
    })
  }

  showReservation(index: number){
    let service = this.serviceList[index];
    service.show = !service.show;
    this.serviceService.updateService(service).subscribe(data => {
      if (data.status == "ok") {
        this.serviceList[index].show = data.data.show;
      }
    })
  }

  reservation(uuid: string) {
    if (this.field == "0") {
      this.router.navigate(['/reservation/' + uuid])
    }
  }

  editTableElement(service: Service, type: string){
    const dialogRef = this.dialog.open(TableFormModalComponent, {
      data: {supplier_uuid: service.reservation.supplier_uuid, driver_uuid: service.driver_uuid, vehicle_uuid: service.vehicle_uuid, type: type },
    })

    dialogRef.afterClosed().subscribe(res => {
      if (res.supplier_uuid != service.reservation.supplier_uuid) {
        service.reservation.total = service.reservation.total == null ? 0 : service.reservation.total;
        service.reservation.commission = service.reservation.commission == null ? 0 : service.reservation.commission;
        service.reservation.supplier_uuid = res.supplier_uuid;
        this.reservationService.updateReservation(service.reservation).subscribe(data => {
          if (this.field != "0") {
            this.getServicesByReservation();
          } else {
            this.getServices();
          }
          if(this.sort.active){
            this.sortData(this.sort);
          }
        })
      }

      if (res.driver_uuid != service.driver_uuid || res.vehicle_uuid != service.vehicle_uuid) {
        service.driver_uuid = res.driver_uuid;
        service.vehicle_uuid = res.vehicle_uuid;
        this.serviceService.updateService(service).subscribe(data => {
          if(data.status == "ok"){
            if (this.field != "0") {
              this.getServicesByReservation();
            } else {
              this.getServices();
            }
            if(this.sort.active){
              this.sortData(this.sort);
            }
          }
        })
      }
    });
  }

  sortData(sort: Sort) {

    this.sort = sort;

    this.serviceList = [];

    this.splitServices();

    if (!sort.active || sort.direction === '') {
      this.joinArrays();
      this.dataSource = new MatTableDataSource(this.serviceList);
      return;
    }

    this.day1List = this.day1List.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplier':
          return this.compare(a, b, "reservation.supplier.name", isAsc);
        case 'driver':
          return this.compare(a, b, "driver.name", isAsc);
        case 'vehicle':
          return this.compare(a, b, "vehicle.license_plate", isAsc);
        case 'date':
        case 'date_H':
          return this.compare(a, b, "date", isAsc);
        case 'location':
          return this.compare(a, b, "origin_location.name", isAsc);
        case 'destination':
          return this.compare(a, b, "destination_location.name", isAsc);
        case 'dateF':
          return this.compare(a, b, "end_date", isAsc);
        case 'reference':
          return this.compare(a, b, "reservation.reference", isAsc);
        case 'customer_reference':
          return this.compare(a, b, "reservation.customer_reference", isAsc);
        case 'description':
          return this.compare(a, b, "reservation.description", isAsc);
        default:
          return 0;
      }
    });

    this.day2List = this.day2List.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplier':
          return this.compare(a, b, "reservation.supplier.name", isAsc);
        case 'driver':
          return this.compare(a, b, "driver.name", isAsc);
        case 'vehicle':
          return this.compare(a, b, "vehicle.license_plate", isAsc);
        case 'date':
        case 'date_H':
          return this.compare(a, b, "date", isAsc);
        case 'location':
          return this.compare(a, b, "origin_location.name", isAsc);
        case 'destination':
          return this.compare(a, b, "destination_location.name", isAsc);
        case 'dateF':
          return this.compare(a, b, "end_date", isAsc);
        case 'reference':
          return this.compare(a, b, "reservation.reference", isAsc);
        case 'customer_reference':
          return this.compare(a, b, "reservation.customer_reference", isAsc);
        case 'description':
          return this.compare(a, b, "reservation.description", isAsc);
        default:
          return 0;
      }
    });

    this.day3List = this.day3List.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'supplier':
          return this.compare(a, b, "reservation.supplier.name", isAsc);
        case 'driver':
          return this.compare(a, b, "driver.name", isAsc);
        case 'vehicle':
          return this.compare(a, b, "vehicle.license_plate", isAsc);
        case 'date':
        case 'date_H':
          return this.compare(a, b, "date", isAsc);
        case 'location':
          return this.compare(a, b, "origin_location.name", isAsc);
        case 'destination':
          return this.compare(a, b, "destination_location.name", isAsc);
        case 'dateF':
          return this.compare(a, b, "end_date", isAsc);
        case 'reference':
          return this.compare(a, b, "reservation.reference", isAsc);
        case 'customer_reference':
          return this.compare(a, b, "reservation.customer_reference", isAsc);
        case 'description':
          return this.compare(a, b, "reservation.description", isAsc);
        default:
          return 0;
      }
    });

    this.joinArrays();

    this.dataSource = new MatTableDataSource(this.serviceList);
  }

  compare(a: Service, b: Service, field: string, isAsc: boolean) {
    const valueA = this.getNestedFieldValue(a, field);
    const valueB = this.getNestedFieldValue(b, field);

    if (valueA === valueB) {
      return (a.date < b.date ? -1 : 1);
    }
    return (valueA < valueB ? -1 : 1) * (isAsc ? 1 : -1);
  }

  getNestedFieldValue(obj: any, fieldPath: string): any {
    const fields = fieldPath.split('.');
    let value = obj;
    for (const field of fields) {
      value = value?.[field];
    }
    return value;
  }

}
