import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Observable, tap, throwError } from 'rxjs';
import { environment } from '../environments/environment';
import { MantenimientoModalComponent } from './components/modal/mantenimiento-modal/mantenimiento-modal.component';
import { RequestModalComponent } from './components/modal/request-modal/request-modal.component';
import { SinServidorModalComponent } from './components/modal/sin-servidor-modal/sin-servidor-modal.component';
import { ApiResponse } from './interfaces/ApiResponse';
import { user } from './interfaces/login';
import { AuthStateService } from './services/authState.service';
import { IsloadingService } from './services/isloading.service';
import { LoginService } from './services/login.service';
import { SecureStorageService } from './services/secureStorage.service';

@Injectable()
export class authInterceptor implements HttpInterceptor {
  secureStorageService = inject(SecureStorageService);
  loginService = inject(LoginService);
  authState = inject(AuthStateService);
  router = inject(Router);
  snackBar = inject(MatSnackBar);
  modal = inject(MatDialog);
  loadingService = inject(IsloadingService);
  intercept(
    req: HttpRequest<ApiResponse<any>>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token = this.secureStorageService.get('token');
    const uid = Math.random().toString(36).slice(2, 7);
    if (
      req.url.includes(environment.apiUrl) &&
      req.url.includes('api') &&
      !req.url.includes('login')
    ) {
      req = req.clone({
        setHeaders: {
          authorization: 'Bearer ' + token,
        },
      });
    }
    this.loadingService.addLoad(uid);
    return next.handle(req).pipe(
      tap({
        next: (ev) => {
          if (ev instanceof HttpResponse) {
            this.loadingService.delLoad(uid);
          }
        },
        error: (requestError) => {
          this.loadingService.delLoad(uid);
          if (
            req.url.includes('api') &&
            !req.url.includes('login') &&
            requestError &&
            requestError.status === 401
          ) {
            this.authState.setAuthState(false);
            this.secureStorageService.clear('token');
            this.loginService.setUser({} as user);
            let snackBarRef = this.snackBar.open('La sesión ha expirado.', '', {
              duration: 5000,
            });
            this.router.navigate(['login']);
          } else if (
            req.url.includes('api') &&
            !req.url.includes('login') &&
            requestError &&
            requestError.status === 403
          ) {
            let snackBarRef = this.snackBar.open(
              'No está autorizado para realizar esta acción.',
              '',
              { duration: 5000 }
            );
            this.router.navigate(['/']);
          } else if (requestError && requestError.status === 503) {
            if (!this.loginService.error) {
              this.modal.open(MantenimientoModalComponent, {
                closeOnNavigation: false,
                disableClose: true,
                width: '30%',
              });
              this.loginService.error = true;
            }
          } else if (requestError && requestError.status === 404) {
            let snackBarRef = this.snackBar.open('No se ha encontrado.', '', {
              horizontalPosition: 'center',
              duration: 10000,
            });
          } else if (requestError && requestError.status === 429) {
            if (!this.loginService.error) {
              this.modal.open(RequestModalComponent, {
                closeOnNavigation: false,
                disableClose: true,
                width: '30%',
                data: { value: requestError.headers.get('Retry-After') },
              });
              this.loginService.error = true;
            }
          } else if (requestError && requestError.status === 0) {
            if (!this.loginService.error) {
              this.modal.open(SinServidorModalComponent, {
                closeOnNavigation: false,
                disableClose: true,
                width: '30%',
              });
            }
            this.loginService.error = true;
          }
          if (requestError.error.errno) {
            let snackBarRef = this.snackBar.open(requestError.error.errno, '', {
              horizontalPosition: 'center',
              duration: 10000,
            });
          }
          return throwError(() => requestError);
        },
      })
    );
  }
}
